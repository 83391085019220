import { MuteButton } from './../view/MuteButton';
import { ShapeVO } from './../core/vo/ShapeVO';
import { Shape } from './../view/Shape';
import { API } from './../services/API';
import { Pallete } from './../core/Pallete';
import * as Assets from '../assets';
import State from '../core/State';
import SFX from '../utils/SFX';
import Music from '../utils/Music';

export default class Sound extends State {

    private _musicToggle: MuteButton;
    private _sfxToggle: MuteButton;
    private _voToggle: MuteButton;

    public static STATE: { m: boolean, sfx: boolean, vo: boolean } = { m: true, sfx: true, vo: true };

    public create(): void {

        var bg = this.game.add.sprite(0, 0, "Menu-BG");

        var header = this.game.add.bitmapText(0, 0, Assets.BitmapFonts.FontsVag.getName(), "Sound", 90);
        header.tint = 0xffffff;
        header.align = "center";
        header.x = this.game.width / 2;
        header.y = 100;
        header.anchor.setTo(.5, 0);

        var k = "back-btn.png";
        var backBtn = this.game.add.button(60, 60, Assets.Atlases.AtlasesEyelander.getName(), this.onBack, this, k, k, k, k);
        backBtn.fixedToCamera = true;
        backBtn.anchor.setTo(0, 0);

        var p = this.game.add.sprite(this.game.width / 2, this.game.height / 2 + 100, Assets.Images.ImagesSettingsPanel.getName());
        p.anchor.setTo(.5);

        this._musicToggle = this.addToggleButton(this.game.width / 2 - 200, 470, "Music", Sound.STATE.m);
        this._musicToggle.ON_TOGGLE.add(this.onMusicToggled, this);
        this.add.existing(this._musicToggle);

        this._sfxToggle = this.addToggleButton(this.game.width / 2 - 200, 620, "Sound FX", Sound.STATE.sfx);
        this._sfxToggle.ON_TOGGLE.add(this.onSFXToggled, this);
        this.add.existing(this._sfxToggle);

        this._voToggle = this.addToggleButton(this.game.width / 2 - 200, 770, "Voice Over", Sound.STATE.vo);
        this._voToggle.ON_TOGGLE.add(this.onVOToggled, this);
        this.add.existing(this._voToggle);

        Music.play(Assets.Audio.AudioMenu.getName());

    }

    private onMusicToggled(s:boolean):void
    {
        Music.mute(!s);
        Sound.STATE.m = s;
    }

    private onSFXToggled(s:boolean):void
    {
        Sound.STATE.sfx = s;
    }

    private onVOToggled(s:boolean):void
    {
        Sound.STATE.vo = s;
    }

    private addToggleButton(x: number, y: number, labelText: string, initialState:boolean): MuteButton {
        var toggle = new MuteButton(this.game, initialState);
        toggle.x = x;
        toggle.y = y;

        var label = this.game.add.bitmapText(0, 0, Assets.BitmapFonts.FontsVag.getName(), labelText, 60);
        label.tint = 0xffffff;
        label.align = "left";
        label.x = toggle.width + 40;
        label.anchor.setTo(0, .5);
        toggle.addChild(label);

        return toggle;
    }

    onBack(): void {
        SFX.play("Button Click 3");
        this.transition("title");
    }
}
