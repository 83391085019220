import * as Assets from '../assets';
import State from '../core/State';
import SFX from '../utils/SFX';
import Music from '../utils/Music';
import InputConfig from '../input/InputConfig';
import { InputMode } from '../enum/InputMode';

export default class Title extends State {

    shapes: Phaser.Group;

    public create(): void {

        var bg = this.game.add.sprite(0, 0, "Menu-BG");
        var logo = this.game.add.sprite(this.game.width / 2, 151, Assets.Atlases.AtlasesEyelander.getName(), "Menu-LOGO.png");
        logo.anchor.setTo(.5, 0);

        var startBtn = this.game.add.button(this.game.world.centerX + 20, 756, Assets.Atlases.AtlasesEyelander.getName(), this.onStart, this, "start-btn.png", "start-btn.png", "start-btn.png", "start-btn.png");

        var settingsBtn = this.game.add.button(this.game.world.centerX - 20, startBtn.y, Assets.Atlases.AtlasesEyelander.getName(), this.onSettings, this, "settings-btn.png", "settings-btn.png", "settings-btn.png", "settings-btn.png");
        settingsBtn.anchor.setTo(1, 0);
        
        var soundBtn = this.game.add.button(this.game.world.centerX - 20, startBtn.y, Assets.Atlases.AtlasesEyelander.getName(), this.onSound, this, "sound-btn.png", "sound-btn.png", "sound-btn.png", "sound-btn.png");
        soundBtn.anchor.setTo(0, 0);
        soundBtn.x = 60;
        soundBtn.y = 60;

        Music.play(Assets.Audio.AudioMenu.getName());

       // this.game.camera.flash(0xffffff, 1000);

       console.log("API? " + API_ENDPOINT);
    }

    onSound():void
    {
        this.transition("sound");
    }

    onStart(): void {
        SFX.play("Button Click 4");
        var tutorialSteps = HIDDEN_SHAPES ? 2 : 1;
        if (this.model.unlocked > 0 || this.model.tutorialStep > tutorialSteps || this.model.playthrough > 0) {
            this.transition("progressmap");
        } else {
            this.transition("tutorialguide");
        }
    }

    onSettings(): void {
        
        SFX.play("Button Click 4");

        if(InputConfig.Mode == InputMode.Touch)
        {
            this.transition("settingstouch");
        }else{
            console.log("go to settings");
            this.transition("settings");

        }
    }

}
