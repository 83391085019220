import { InputMode } from './../enum/InputMode';
import Settings from "./Settings";
import InputConfig from '../input/InputConfig';

export default class SettingsTouch extends Settings
{
    buildLayout():void
    {
        var s = this.addShapeSizeButtons();
        s.y = 310;
        s.x = 0;

        var t = this.addTimeButtons();
        t.y = 660
        t.x = -235;

        var m = this.addInputModeButtons();
        m.y = 660;
        m.x = 275;
  
        this.setSize(this.model.shapesize);
        this.setGameSpeed(this.model.gamespeed);
        this.setInputMode(InputConfig.Mode);
    }
}