export class PoissonDiskSimple {
    private cellSize: number;
    private gridWidth:number;
    private gridHeight:number;
    private grid:number[][];
    private queue:any[];
    private queueSize:number;
    private sampleSize:number;
    private radius2:number;
    private width:number;
    private height:number;
    private R:number;
    private k:number;
    private x:number;
    private y:number;

    constructor(width: number, height: number, radius: number) {
        
        this.k = 30; // maximum number of samples before rejection
        this.radius2 = radius * radius;
        this.R = 3 * this.radius2;
        this.cellSize = radius * Math.SQRT1_2;

        this.gridWidth = Math.ceil(width / this.cellSize);
        this.gridHeight = Math.ceil(height / this.cellSize);
        this.grid = new Array<number[]>(this.gridWidth * this.gridHeight);
        this.queue = [];
        this.queueSize = 0;
        this.sampleSize = 0;
        this.width = width;
        this.height = height;
    }

     private far(x: number, y: number): Boolean {
        let i = x / this.cellSize | 0;
        let j = y / this.cellSize | 0;

        let i0 = Math.max(i - 2, 0);
        let j0 = Math.max(j - 2, 0);
        let i1 = Math.min(i + 3, this.gridWidth);
        let j1 = Math.min(j + 3, this.gridHeight);

        for (j = j0; j < j1; ++j) {
            let o = j * this.gridWidth;

            for (i = i0; i < i1; ++i) {
                let s;

                if ((s = this.grid[o + i])) {
                    let dx = s[0] - x,
                        dy = s[1] - y;

                    if (dx * dx + dy * dy < this.radius2) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

  private sample(x:number, y:number):Array<number> {
    let s = [x, y];

    this.queue.push(s);

    this.grid[this.gridWidth * (y / this.cellSize | 0) + (x / this.cellSize | 0)] = s;

    this.sampleSize++;
    this.queueSize++;

    return s;
}

public return():Array<number> {
    if (!this.sampleSize) {
        return this.sample(Math.random() * this.width, Math.random() * this.height);
    }

    // Pick a random existing sample and remove it from the queue.
    while (this.queueSize) {
        let i = Math.random() * this.queueSize | 0;
        let s = this.queue[i];

        // Make a new candidate between [radius, 2 * radius] from the existing
        // sample.
        for (let j = 0; j < this.k; ++j) {
            let a = 2 * Math.PI * Math.random();
            let r = Math.sqrt(Math.random() * this.R + this.radius2);
            let x = s[0] + r * Math.cos(a);
            let y = s[1] + r * Math.sin(a);

            // Reject candidates that are outside the allowed extent,
            // or closer than 2 * radius to any existing sample.
            if (x >= 0 && x < this.width && y >= 0 && y < this.height && this.far(x, y)) {
                return this.sample(x, y);
            }
        }

        this.queue[i] = this.queue[--this.queueSize];
        this.queue.length = this.queueSize;
    }
};
}
