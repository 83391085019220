import { StandardUI } from './../view/StandardUI';

import { DomUtils } from './../utils/utils';
import { ShapeSpawner } from './../core/ShapeSpawner';
import { Shape } from './../view/Shape';
import { Pallete } from './../core/Pallete';
import { ShapeVO } from './../core/vo/ShapeVO';
import * as Assets from '../assets';
import * as AssetUtils from '../utils/assetUtils';
import State from '../core/State';
import { Form } from "../enum/Form";
import SFX from '../utils/SFX';
import Music from '../utils/Music';
import { MapAvatar } from './../sprites/MapAvatar';

export default class ProgressMap extends State {

    private _backgrounds: Phaser.Group;
    private _buttons: Phaser.Group;
    private _ui: StandardUI;
    private _marker: MapAvatar;
    private _chain: Phaser.Tween;

    public static _LastLevelUnlocked = 0;
    private static _intro: boolean = true;

    private static _PATH: Array<Phaser.Point> = new Array();
    private static _PATH_DOTS: Array<number> = new Array();

    public create(): void {

        if (ProgressMap._PATH.length == 0) this.initPath();

        //reset
        if (this.model.unlocked == 0) ProgressMap._LastLevelUnlocked = 0;

        this.AddBackgrounds();
        this.AddWorldButtons();
        this.AddMarker();
        this.AddUI();

        if (this.model.unlocked == 0 || ProgressMap._intro == true) this.camera.y = 0;
        else this.camera.y = this.GetWorldPosition(this.lastWorldUnlocked).y;

        if (ProgressMap._intro == true || this.model.world != this.lastWorldUnlocked || this.model.unlocked == 0) {
            this.IntroViaPan();
        } else {
            this.Intro();
        }

        ProgressMap._intro = false;

        Music.playWorldMusic(this.model.world);
        // this.game.camera.flash(0xffffff, 1000);
    }

    private IntroViaPan(): void {
        this.game.add.tween(this.camera).to({ y: this.GetWorldPosition(this.model.world).y }, 2000, Phaser.Easing.Cubic.Out, true, 2000).onComplete.addOnce(this.Intro, this);
    }

    private Intro(): void {
        if (this.lastLevelUnlocked < this.model.unlocked) {
            this.MoveMarker(this.model.unlocked);
        }

        this._ui.enabled = true;
        this.lastLevelUnlocked = this.model.unlocked;
    }

    private OnPlay(): void {
        SFX.play("Button Click 4");
        this.transition("instructions");
    }

    private OnBack(): void {
        SFX.play("Button Click 3");
        this.transition("title");
    }

    private AddUI(): void {

        this._ui = new StandardUI(this.game, true);
        this._ui.ON_BACK.addOnce(this.OnBack, this);
        this._ui.ON_PLAY.addOnce(this.OnPlay, this);
        this.game.add.existing(this._ui);

        this._ui.enabled = false;
    }


    private GetWorldPosition(index: number): Phaser.Point {

        if (index < 0) index = 0;

        var count = 0;
        for (var i = this._backgrounds.length - 1; i > 0; i--) {
            if (index == count) {
                var world = this._backgrounds.children[this._backgrounds.length - 1 - count] as Phaser.Sprite;
                return new Phaser.Point(world.position.x + world.width / 2, world.position.y);
            }
            count++;
        }

        return new Phaser.Point(0, 0);

    }

    private GetWorldDotPosition(index: number): Phaser.Point {
        if (index < 0) index = 0;
        return ProgressMap._PATH[ProgressMap._PATH_DOTS[Math.floor(index)]];
    }

    private AddBackgrounds(): void {
        this._backgrounds = this.game.add.group();

        for (var i = 0; i < 4; i++) {
            var key: string = "map_0" + (i + 1).toString();
            var slice = this.game.add.sprite(0, i * 1080, key, null, this._backgrounds);
        }
    }

    private AddWorldButtons(): void {
        this._buttons = this.game.add.group();

        for (var i = 0; i < ProgressMap._PATH_DOTS.length; i++) {
            var m: ProgressBtnMode;
            var pos = this.GetWorldDotPosition(i);
            if (i <= this.model.unlocked) m = ProgressBtnMode.Open;
            else m = ProgressBtnMode.Locked;

            var btn = this.makeButton(pos.x, pos.y, i, m);
            this._buttons.add(btn);
        }
    }

    private AddMarker(): void {
        var pos = this.GetWorldDotPosition(this.lastLevelUnlocked);
        this._marker = new MapAvatar(this.game, pos.x, pos.y);
        this.game.add.existing(this._marker);
    }

    private MoveMarker(level: number): void {

        //TPH error happens when end is null or while loop gets stuck

        var targetPos = this.GetWorldDotPosition(level);
        var end = null;
        var previousStep = null;
        var pathIndex: number = ProgressMap._PATH_DOTS[Math.floor(this.lastLevelUnlocked)];

        if (targetPos != this.GetPathPoint(pathIndex)) {
            while (end != targetPos) {
                pathIndex++;

                var start = (end == null) ? this._marker.position : end;
                end = this.GetPathPoint(pathIndex);

                var ease = (end == targetPos) ? Phaser.Easing.Cubic.Out : null;

                if (this._chain == null) {
                    this._chain = this.game.add.tween(this._marker).to({ x: end.x, y: end.y },
                        this.GetMoveSpeed(this._marker.position, end), null, false, 500);
                } else {
                    this._chain.to({ x: end.x, y: end.y, }, this.GetMoveSpeed(start, end), null, false, 0);
                }

                //console.log("next step: " + end.x + " / " + end.y);
            }
            this._chain.start();

        }

    }

    private GetMoveSpeed(from: Phaser.Point, to: Phaser.Point): number {
        var pxPerSec: number = 300;

        return (Phaser.Point.distance(from, to) / pxPerSec) * 1000;
    }

    private get lastWorldUnlocked(): number {
        return Math.floor(ProgressMap._LastLevelUnlocked / 3);
    }

    private get lastLevelUnlocked(): number {
        return ProgressMap._LastLevelUnlocked;
    }

    private set lastLevelUnlocked(value: number) {
        ProgressMap._LastLevelUnlocked = value;
    }

    private makeButton(x: number, y: number, index: number, mode: ProgressBtnMode): ProgressBtn {

        var k = this.GetProgressBtnKey(index);

        var btn = new ProgressBtn(this.game, index + 1, k, mode);
        btn.x = x;
        btn.y = y;

        return btn;

    }

    private GetProgressBtnKey(index: number): string {
        if (index <= 2) return "sand_thumb.png";
        else if (index <= 5) return "grass_thumb.png";
        else if (index <= 8) return "canyon_thumb.png";
        else return "lava_thumb.png";
    }

    private GetPathPoint(index: number): Phaser.Point {
        return ProgressMap._PATH[index];
    }

    shutdown(): void {
        if (this._chain != null) {
            this._chain.stop(false);
            this._chain = null;
        }

        super.shutdown(this.game);
    }

    private initPath(): void {
        ProgressMap._PATH_DOTS.push(0);
        ProgressMap._PATH_DOTS.push(3);
        ProgressMap._PATH_DOTS.push(5);
        ProgressMap._PATH_DOTS.push(8);
        ProgressMap._PATH_DOTS.push(10);
        ProgressMap._PATH_DOTS.push(12);
        ProgressMap._PATH_DOTS.push(14);
        ProgressMap._PATH_DOTS.push(17);
        ProgressMap._PATH_DOTS.push(19);
        ProgressMap._PATH_DOTS.push(22);
        ProgressMap._PATH_DOTS.push(24);
        ProgressMap._PATH_DOTS.push(25);

        ProgressMap._PATH.push(new Phaser.Point(936, 4141));
        ProgressMap._PATH.push(new Phaser.Point(936, 3936));
        ProgressMap._PATH.push(new Phaser.Point(1606, 3936));
        ProgressMap._PATH.push(new Phaser.Point(1606, 3736));
        ProgressMap._PATH.push(new Phaser.Point(1606, 3546));
        ProgressMap._PATH.push(new Phaser.Point(939, 3546));
        ProgressMap._PATH.push(new Phaser.Point(291, 3546));
        ProgressMap._PATH.push(new Phaser.Point(291, 2994));
        ProgressMap._PATH.push(new Phaser.Point(714, 2994));
        ProgressMap._PATH.push(new Phaser.Point(1525, 2994));
        ProgressMap._PATH.push(new Phaser.Point(1525, 2838));
        ProgressMap._PATH.push(new Phaser.Point(1525, 2481));
        ProgressMap._PATH.push(new Phaser.Point(1135, 2481));
        ProgressMap._PATH.push(new Phaser.Point(730, 2481));
        ProgressMap._PATH.push(new Phaser.Point(730, 1885));
        ProgressMap._PATH.push(new Phaser.Point(730, 1770));
        ProgressMap._PATH.push(new Phaser.Point(1402, 1770));
        ProgressMap._PATH.push(new Phaser.Point(1402, 1561));
        ProgressMap._PATH.push(new Phaser.Point(1402, 1351));
        ProgressMap._PATH.push(new Phaser.Point(919, 1351));
        ProgressMap._PATH.push(new Phaser.Point(468, 1351));
        ProgressMap._PATH.push(new Phaser.Point(468, 919));
        ProgressMap._PATH.push(new Phaser.Point(727, 919));
        ProgressMap._PATH.push(new Phaser.Point(1021, 919));
        ProgressMap._PATH.push(new Phaser.Point(1021, 651));
        ProgressMap._PATH.push(new Phaser.Point(1021, 349));


    }


}


class ProgressBtn extends Phaser.Sprite {
    private _lockedCol: number = 0xcccccc;
    private _openCol: number = 0x00ff00;

    public static SIZE: number = 150;

    private _index: number;
    public ON_SELECTED: Phaser.Signal = new Phaser.Signal();
    private _mode: ProgressBtnMode;
    private _lock: Phaser.Sprite;

    constructor(game: Phaser.Game, index: number, k: string, mode: ProgressBtnMode = ProgressBtnMode.Open) {
        super(game, 0, 0, Assets.Atlases.AtlasesEyelander.getName(), k);
        this.anchor.setTo(.5);

        var str = index.toString();
        var label = this.game.add.bitmapText(0, 0, Assets.BitmapFonts.FontsVag.getName(), str, 55);
        label.anchor.setTo(0.5);
        this.addChild(label);

        this._lock = this.game.add.sprite(0, 0, Assets.Atlases.AtlasesEyelander.getName(), "padlock.png");
        this._lock.anchor.setTo(.5, .5);
        this.addChild(this._lock);

        this._index = index;
        this.mode = mode;
    }

    private onSelected(): void {
        this.ON_SELECTED.dispatch(this._index);
    }


    public set mode(m: ProgressBtnMode) {
        this._mode = m;

        switch (m) {
            case ProgressBtnMode.Locked:
                this._lock.visible = true;
                break;

            case ProgressBtnMode.Open:
                this._lock.visible = false;
                break;
        }
    }

    public get mode(): ProgressBtnMode {
        return this._mode;
    }
}

enum ProgressBtnMode { Open, Locked, Selected }

