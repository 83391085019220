import * as Assets from '../assets';

export class TouchBlip extends Phaser.Sprite {
    
    public ON_COMPLETE:Phaser.Signal = new Phaser.Signal();
    
    constructor(game:Phaser.Game)
    {
        super(game, 0,0, Assets.Atlases.AtlasesPreloader.getName(), "touch_blip.png");
        this.anchor.setTo(.5,.5);
    }

    public onTouch(x:number, y:number):void
    {
        this.x = x;
        this.y = y;

        this.scale.setTo(0,0);
        this.alpha = 1;

        //play animation
    }

    update():void
    {
        var s = this.scale.x;
        if(s < 1)
        {
            s+= (this.game.time.elapsed/1000)*2;
        }else{
            s = 1;
        }

        this.scale.setTo(s,s);
        this.alpha = Phaser.Math.clamp(1-s, 0, 1);
    }
}