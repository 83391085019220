import { Seed } from './../ShapeSpawner';
import { Pallete } from './../Pallete';
import { EnumUtils } from './../../utils/utils';
import { Form } from "../../enum/Form";
import { Prop } from "../../enum/Prop";

export class ShapeVO {

    public data: {
        form: Form,
        tint: number,
        scale: number,
        rotation: number,
        flash: boolean
    } = {
        form: Form.Circle,
        tint: 0xff0000,
        scale: 1,
        rotation: 0,
        flash: false,
    }

    public uniqueness: Array<string> = new Array<string>();


}



