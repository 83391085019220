import { StandardUI } from './../view/StandardUI';
import { DomUtils } from './../utils/utils';
import { ShapeSpawner } from './../core/ShapeSpawner';
import { Shape } from './../view/Shape';
import { Pallete } from './../core/Pallete';
import { ShapeVO } from './../core/vo/ShapeVO';
import * as Assets from '../assets';
import * as AssetUtils from '../utils/assetUtils';
import State from '../core/State';
import { Form } from "../enum/Form";
import Music from '../utils/Music';


export default class Instructions extends State {

    private _ui: StandardUI;

    public create(): void {

        var count: number = DomUtils.getQueryVariable("distractions");
        this.model.newLevel((count == 0) ? null : count);

        var target: ShapeVO = this.model.target;

        var instructions = this.game.add.bitmapText(this.game.width / 2, 100, Assets.BitmapFonts.FontsVag.getName(), "", 70);
        instructions.anchor.setTo(.5, 0);
        instructions.align = "center";
        instructions.text = this.buildDescription(target, this.model.distractions);

        var mag = this.game.add.sprite(this.game.width / 2 - 221, this.game.height / 2 - 276, Assets.Atlases.AtlasesEyelander.getName(), "magnifyingglass.png");

        var shp: Shape = new Shape(this.game, this.game.width / 2, this.game.height / 2 - 60, target);
        this.game.add.existing(shp);

        this.AddNotches();

        if (DomUtils.getQueryVariable("debug") == 'true') {

            var distractions = this.game.add.group();

            for (var i = 0; i < this.model.distractions.length; i++) {
                var d = new Shape(this.game, i * 100, 85, this.model.distractions[i]);
                d.x += d.width / 2;
                d.alpha = .9;
                distractions.add(d);
            }

            distractions.y = shp.y + shp.height + 100;
            distractions.x = this.game.world.centerX - distractions.width / 2;

            var l = this.game.add.text(distractions.width / 2, 0, 'Distractions', {
                font: '20px ' + Assets.GoogleWebFonts.Barrio, fill: "#ffffff"
            });
            l.align = "center";
            l.anchor.setTo(.5);
            distractions.add(l);
        }

        this.game.input.onDown.addOnce(this.onPlay, this);

        Music.playWorldMusic(this.model.world, .4);
        this.addUI();
    }

    private addUI(): void {
        this._ui = new StandardUI(this.game);
        this._ui.ON_BACK.addOnce(this.onBack, this);
        this._ui.ON_PLAY.addOnce(this.onPlay, this);
        this.game.add.existing(this._ui);
    }

    private AddNotches(): void {

        console.log("add notches: " + this.model.unlocked);

        var total = this.model.getTotalStagesForLevel(this.model.unlocked) + 1;
        var key = this.getNotchKey(this.model.world);
        var notches: Phaser.Group = this.game.add.group();
        var maxpadding = 80;

        for (var i = 0; i < total; i++) {
            var notch = this.game.add.sprite(0, 0, Assets.Atlases.AtlasesEyelander.getName(), key, notches);
            var padding = (this.game.width - 300 - (notch.width * total)) / total;
            if (padding > maxpadding) padding = maxpadding;

            notch.x = i * (notch.width + padding);

            var label = this.game.add.bitmapText(notch.width / 2, notch.height / 2, Assets.BitmapFonts.FontsVag.getName(), (i + 1).toString(), 50);
            label.align = "center";
            label.anchor.setTo(.5);
            notch.addChild(label);
        }

        if (total > 1) {
            var line = this.game.add.sprite(0, 0, Assets.Atlases.AtlasesEyelander.getName(), "whiteline.png");
            line.anchor.setTo(0, .5);
            line.x = notch.width / 2;
            line.y = notch.height / 2;
            line.width = notches.width - notch.width;
            notches.addAt(line, 0, true);
        }

        var index: number = this.model.stage;
        console.log("unlocked: " + this.model.unlocked + " / " + index + " + index");
        var x = notch.width / 2 + index * (notch.width + padding);
        var marker = this.game.add.sprite(x, 0, Assets.Atlases.AtlasesEyelander.getName(), "level_marker.png", notches);
        marker.anchor.setTo(.5, 1.2);

        notches.x = this.game.width / 2 - notches.width / 2;
        notches.y = 897;
    }

    private getNotchKey(world: number): string {
        if (world == 0) return "sand_thumb_small.png";
        else if (world == 1) return "grass_thumb_small.png";
        else if (world == 2) return "canyon_thumb_small.png";
        else return "lava_thumb_small.png";
    }

    private onFullScreen(): void {
        // Stretch to fill
        if (this.game.scale.isFullScreen) {
            this.game.scale.stopFullScreen();
        }
        else {
            this.game.scale.startFullScreen(false);
        }

    }

    private buildDescription(target: ShapeVO, distractions: ShapeVO[]): string {

        var form: string;
        var tint: string;
        var variation: string;

        for (var i = 0; i < target.uniqueness.length; i++) {
            var u = target.uniqueness[i];
            if (u == "form") {
                form = Form[target.data.form];
            }
            else if (u == "tint") {
                tint = Pallete.GetName(target.data.tint);
            }
            else if (u == "scale") {
                if (target.data.scale == 0.75) { variation = (this.areAllEqual(distractions, "scale", target.data.scale) == false) ? "small" : ""; }
                else if (target.data.scale == 1.25) { variation = (this.areAllEqual(distractions, "scale", target.data.scale) == false) ? "large" : ""; }
            } else if (u == "rotation") {
                if (target.data.rotation == 0) {
                    variation = (this.areAllEqual(distractions, "rotation", target.data.rotation) == false) ? "not rotated" : "";
                }
                else { variation = "rotated"; }
            } else if (u == "flash") {
                if (target.data.flash == true) { variation = "flashing"; }
                else { variation = (this.areAllEqual(distractions, "flash", false) == false) ? "not flashing" : ""; }
            }

        }

        var append: string = (variation == "") ? "" : " that is " + variation;
        return "Find the " + tint + " " + form + append;
    }

    private areAllEqual(distractions: ShapeVO[], key: string, value: any): boolean {
        for (var i = 0; i < distractions.length; i++) {
            if (distractions[i].data[key] != value) return false;
        }

        return true;
    }

    private onPlay(): void {
        this.transition("game");
    }

    private onBack(): void {
        this.transition("title");
    }
}
