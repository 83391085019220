export default class SwipeGesture {
    
    private _tStart:number = 0;
    private _game:Phaser.Game;
    
    private _threshold = 50;

    public ON_SWIPE:Phaser.Signal = new Phaser.Signal();

    constructor(game:Phaser.Game, threshold:number = 50) {
        game.input.onUp.add(this.onTouchUp, this);
        game.input.onDown.add(this.onTouchDown, this);
    
        this._threshold = threshold;
        this._game = game;
    }

    private onTouchUp():void
    {
        if(Math.abs(this._tStart - this._game.input.x) > this._threshold)
        {
            this.ON_SWIPE.dispatch();
        }
    }

    private onTouchDown():void
    {
        this._tStart = this._game.input.x;
    }
}