import { DomUtils } from './../utils/utils';
import { ShapeSpawner } from './../core/ShapeSpawner';
import { Shape } from './../view/Shape';
import { Pallete } from './../core/Pallete';
import { ShapeVO } from './../core/vo/ShapeVO';
import * as Assets from '../assets';
import * as AssetUtils from '../utils/assetUtils';
import State from '../core/State';
import { Form } from "../enum/Form";
import SFX from '../utils/SFX';


export  class MapAvatar extends Phaser.Sprite {

    private _direction: Phaser.Point;
    private _previousPos: Phaser.Point;

    constructor(game: Phaser.Game, x: number, y: number) {
        super(game, x, y, Assets.Atlases.AtlasesEyelander.getName(), "walk_side_00000.png");

        this.animations.add("walk_idle", Phaser.Animation.generateFrameNames("walk_idle_", 0, 28, ".png", 5), 29, true);
        this.animations.add("walk_side", Phaser.Animation.generateFrameNames("walk_side_", 0, 28, ".png", 5), 29, true);
        this.animations.add("walk_up", Phaser.Animation.generateFrameNames("walk_up_", 0, 28, ".png", 5), 29, true);

        this.animations.play("walk_idle");

        this._direction = new Phaser.Point();
        this._previousPos = new Phaser.Point()

        this.anchor.setTo(.5, .8);
    }

    update(): void {
        super.update();
        this.updateDirection();
    }

    private updateDirection(): void {

        if(this.position.x > this._previousPos.x) this._direction.x = 1;
        else if(this.position.x < this._previousPos.x) this._direction.x = -1;
        else this._direction.x = 0;

        if(this.position.y < this._previousPos.y) this._direction.y = 1;
        else if(this.position.y <= this._previousPos.y) this._direction.y = 0; 

        if(this._direction.x == -1)
        {
            this.animations.play("walk_side");
            this.scale.x = -1;
        }else if(this._direction.x == 1)
        {
            this.animations.play("walk_side");
            this.scale.x = 1;
        }else if(this._direction.y == 1)
        {
            this.animations.play("walk_up");
            this.scale.x = 1;
        }else{
            this.animations.play("walk_idle");
            this.scale.x = 1;
        }

        this._previousPos.x = this.position.x; this._previousPos.y = this.position.y;
    }
}