import { StringUtils } from './../utils/utils';
import * as Assets from '../assets';
import SFX from '../utils/SFX';
import State from '../core/State';
import Sound from '../states/Sound';


export default class CutScene extends State {

    private _sceneImage:Phaser.Image;
    private _scene: Phaser.Video;
    private _ON_VIDEO_COMPLETE: Phaser.Signal = new Phaser.Signal();

    private _loadingText:Phaser.BitmapText;

    public create(): void {

        this._ON_VIDEO_COMPLETE.addOnce(this.onSceneComplete, this);

        this._loadingText = this.game.add.bitmapText(this.game.width / 2, 100, Assets.BitmapFonts.FontsVag.getName(), "Loading...", 50);
        this._loadingText.anchor.setTo(.5, .5);
        this._loadingText.y = this.game.height / 2;
        this._loadingText.align = "center";
        this._loadingText.tint = 0xffffff;

        this._loadingText.alpha = 0;
        this.game.add.tween(this._loadingText).to( { alpha: 1 }, 800, Phaser.Easing.Linear.None, true, 0, 1000, true);


        this.game.camera.flash(0x000000, 1000);
        this._scene = this.game.add.video().createVideoFromURL(this.getScene(), true);
        this._scene.mute = !Sound.STATE.sfx;
        this._scene.onComplete.addOnce(this.onSceneComplete, this);
        this._scene.loop = false;
        this._sceneImage = this._scene.addToWorld(0, 0, 0, 0, 1, 1);
        this._scene.onError.addOnce(this.onSceneError, this);

        if(this.game.device.iOS == true)
        {
            this._scene.mute = true; // muted videos play inline without user interaction
            this._scene.unlock(); //  manual phaser unlock http://phaser.io/docs/2.4.2/Phaser.Video.html#unlock
            this._scene.video.setAttribute('playsinline', 'playsinline'); // for inline mode
        }

        this.game.input.mousePointer.leftButton.onDown.addOnce(this.onSceneComplete, this);
    }

    private getScene(): string {


        var scene: string = Assets.Video.VideoScene11.getMP4();
        switch (this.model.unlocked) {
            case 1:
                scene = Assets.Video.VideoScene11.getMP4();
                break;
            case 2:
                scene = Assets.Video.VideoScene21.getMP4();
                break;
            case 3:
                scene = Assets.Video.VideoScene31.getMP4();
                break;
            case 4:
                scene = Assets.Video.VideoScene41.getMP4();
                break;
            case 5:
                scene = Assets.Video.VideoScene51.getMP4();
                break;
            case 6:
                scene = Assets.Video.VideoScene61.getMP4();
            break;
            case 7:
                scene = Assets.Video.VideoScene71.getMP4();
            break;
            case 8:
                scene = Assets.Video.VideoScene81.getMP4();
            break;
            case 9:
                scene = Assets.Video.VideoScene911.getMP4();
            break;
            case 10:
                scene = Assets.Video.VideoScene101.getMP4();
            break;
            case 11:
                scene = Assets.Video.VideoScene111.getMP4();
            break;
            case 12:
                scene = Assets.Video.VideoScene121.getMP4();
            break;
        }

        return scene;
              
    
    }

    render(): void {
        if (this._scene.currentTime >= this._scene.duration) {
            this._ON_VIDEO_COMPLETE.dispatch();
        } else {

            //this._scene.textureFrame.width = this.game.scale.bounds.width;
            //this._scene.textureFrame.height = this.game.scale.bounds.height;
            this._scene.width = this.game.scale.bounds.width;
            this._scene.height = this.game.scale.bounds.height;

//console.log("1: " + this.game.width + " / " + this.game.camera.width + " / " + this.game.canvas.width + " / " + window.innerWidth + " / " + this.game.scale.bounds.width + " / " + this.game.scale.boundingParent);
            
        }
    }

    shutdown(): void {

        this._sceneImage.destroy();
        this._sceneImage = null;

        var e = this._scene.video;
        e.pause();
        e.removeAttribute('src'); // empty source
        e.load();

       // this._scene.destroy();
       // this._scene = null;

        super.shutdown(this.game);
    }

    private onSceneError(e?: any): void {
        this.transition('progressmap');
    }

    private onSceneComplete(): void {

        if(this.model.unlocked < 12)
        {
            this.transition("progressmap");
        }else{
            this.transition("completion");
        }
        
    }
  private onScenePlay():void
    {
        console.log("On scene play");
    }
}