import { Model } from './../core/model/Model';
import * as Assets from '../assets';
import { CursorSize } from "../enum/CursorSize";
import Game from '../states/Game';
import { Hand } from '../enum/Hand';
import { InputMode } from '../enum/InputMode';
import InputConfig from '../input/InputConfig';


export default class Cursor extends Phaser.Sprite {

    private _model:Model;

    constructor(g: Phaser.Game, model:Model) {
        super(g, 0,0, Assets.Atlases.AtlasesPreloader.getName(), "cursor_medium.png");
        this._model = model;


        $('body').css('cursor', 'none');
    }

    update(): void {
        let active = false;
        var activeHandlers:Phaser.InputHandler[] = this.game.input.interactiveItems.list;
        var size = this._model.cursorsize;
        for (var i = 0; i < activeHandlers.length; i++) {
            var ih: Phaser.InputHandler = activeHandlers[i];
            ih.useHandCursor = false;
            if (ih.pointerOver() == true && ih != Game.GAME_CLICK_INPUT_HANDLER) active = true;
        }

        this.frameName = this.getCursorFrame(this._model.cursorsize, active);
        
        this.x = this.game.camera.x + this.game.input.activePointer.worldX - this.width;
        this.y =  this.game.input.activePointer.worldY - this.game.camera.y;

        if(this.game.stage.getChildIndex(this) > -1) this.game.stage.setChildIndex(this, this.game.stage.children.length-1);

        this.scale.x = this._model.hand == Hand.Right ? 1 : -1;

        this.visible = InputConfig.Mode == InputMode.Mouse;
        
    }

    private getCursorFrame(size: CursorSize, active: boolean): string {
        var f = "cursor_small.png";

        switch (size) {
            case CursorSize.Little:
                f = (active == true) ? "cursor_small_green.png" : "cursor_small.png";
                break;
            case CursorSize.Regular:
                f = (active == true) ? "cursor_medium_green.png" : "cursor_medium.png";
                break;
            case CursorSize.Big:
                f = (active == true) ? "cursor_large_green.png" : "cursor_large.png";
                break;
        }

        return f;
    }
}