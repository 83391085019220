import * as Assets from '../../assets';
import * as AssetUtils from '../../utils/assetUtils';
import SFX from '../../utils/SFX';

export class Stars extends Phaser.Group {

    private _stars: Phaser.Sprite[];
    public ON_COMPLETE = new Phaser.Signal();

    constructor(game: Phaser.Game) {
        super(game);
        this.visible = false;

        var bg = game.add.sprite(this.game.width / 2, this.game.height / 2, Assets.Atlases.AtlasesEyelander.getName(), Assets.Atlases.AtlasesEyelander.Frames.StarPlaque);
        bg.anchor.setTo(.5, .5);
        this.add(bg);

        this._stars = [];
        for (var i = 0; i < 3; i++) {
            var star: Phaser.Sprite = this.game.add.sprite(0, 0, Assets.Atlases.AtlasesEyelander.getName(), Assets.Atlases.AtlasesEyelander.Frames.StarIcon);
            star.anchor.setTo(.5, .5);
            star.y = bg.y - 45;
            star.x = bg.x + 2;// + bg.width/2;

            this.add(star);
            this._stars.push(star);
        }

        this._stars[0].x -= 238;
        this._stars[2].x += 238;
    }

    public Show(stars: number, delay:number = 0): void {

        this.visible = true;
        this.alpha = 1;

        var speed = 1000;

        this.y = 2000;
        this.game.add.tween(this).to({ y: 0 }, speed, Phaser.Easing.Elastic.Out, true, delay);

        for (var i = 0; i < this._stars.length; i++) {
            var s = this._stars[i];
            //s.alpha = 0;
            s.scale.setTo(0);

            if (i + 1 <= stars) {
                var t = this.game.add.tween(s.scale).to({ x: 1, y: 1 }, 350, Phaser.Easing.Elastic.Out, true, delay + speed + (i * 200));
                if (i + 1 == stars) t.onComplete.addOnce(this.onFeedbackComplete, this);
            }
        }

        //SFX
        if(stars == 1)
        {
            SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.RoundEndNotBad.toString());
        }else if(stars == 2)
        {
            SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.RoundEndGood.toString());
        }else if(stars == 3)
        {
            SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.RoundEndExcellent.toString());
        }
    }

    private onFeedbackComplete(): void {
        this.game.add.tween(this).to({ alpha: 0 }, 500, null, true, 500).onComplete.addOnce(function () {
            this.ON_COMPLETE.dispatch();
        }, this);

    }
}