import { SessionVO } from './core/vo/SessionVO';
import 'p2';
import 'pixi';
import 'phaser';
import { API } from './services/API';
import { Model } from './core/model/Model';
import * as WebFontLoader from 'webfontloader';

import TutorialGuide from './states/tutorial/TutorialGuide';
import TutorialGame from './states/tutorial/TutorialGame';
import TutorialInstructions from './states/tutorial/TutorialInstructions';
import Conjunction from './states/Conjunction';
import Boot from './states/Boot';
import Sound from './states/Sound';
import Preloader from './states/Preloader';
import Title from './states/Title';
import CutScene from './states/CutScene';
import Settings from "./states/Settings"
import SettingsTouch from './states/SettingsTouch';
import ProgressMap from './states/ProgressMap';
import Instructions from './states/Instructions';
import Completion from './states/Completion';
import Game from './states/Game';
import { StandardUI } from './view/StandardUI';
import { MuteButton } from './view/MuteButton';
import { ClosePanel } from './view/ClosePanel';
import { Shape } from './view/Shape';
import { ShapeSpawner } from './core/ShapeSpawner'
import { MapAvatar } from './sprites/MapAvatar';
import { PositiveAnimation } from './sprites/feedback/PositiveAnimation';
import { Stars } from './sprites/feedback/Stars';
import { TouchBlip} from './sprites/TouchBlip';

import SwipeGesture from './input/SwipeGesture';
import InputConfig from './input/InputConfig';

import * as Utils from './utils/utils';
import SFX from './utils/SFX';
import Music from './utils/Music';
import Cursor from './utils/Cursor';
import * as Assets from './assets';
import { InputMode } from './enum/InputMode';


export default class App extends Phaser.Game {

    public static app: App;
    private _model: Model;

    constructor(config: Phaser.IGameConfig, auth: any = null) {
        super(config);

        API.auth = auth;

        this.state.add('boot', Boot);
        this.state.add('preloader', Preloader);
        this.state.add('title', Title);
        this.state.add('cutscene', CutScene);
        this.state.add('progressmap', ProgressMap);
        this.state.add('instructions', Instructions);
        this.state.add('game', Game);
        this.state.add('settings', Settings);
        this.state.add('settingstouch', SettingsTouch);
        this.state.add("sound", Sound);
        this.state.add('tutorialinstructions', TutorialInstructions);
        this.state.add('tutorialgame', TutorialGame);
        this.state.add('tutorialguide', TutorialGuide);
        this.state.add("completion", Completion);

        this.state.onStateChange.add(this.onStateChange, this);

        API.GetUser(this.onUserDataFetched.bind(this), this.onUserDataError.bind(this));
    }

    private onUserDataFetched(data: any, status: string, xhr: any): void {

        if (data != null) {
            var d = JSON.parse(data);
            if (d.data != undefined && d.data.length > 0) {
                var dObj = JSON.parse(d.data[0].thedata);
                this._model = new Model(
                    new SessionVO(
                        dObj.session.unlocked,
                        dObj.session.weighting,
                        dObj.session.concecutivelosses,
                        dObj.session.shapesize,
                        dObj.session.gamespeed,
                        dObj.session.cursorsize),
                    dObj.playthrough)
            } else {
                this._model = new Model(new SessionVO(), 0);
            }
        }else{
            this._model = new Model(new SessionVO(), 0);
        }


        this.state.start('boot', false, false, this._model);
    }

    private onUserDataError(data: any): void {

        //testing
        this._model = new Model(new SessionVO(), 0);
        this.state.start('boot', false, false, this._model);
    }

    private onStateChange(): void {
        this.camera.reset();
        this.camera.resetFX();
        this.camera.fx.position.setTo(0, 0);
    }
}

export function startApp(parent: string = '', auth: any = null): void {
    let gameWidth: number = DEFAULT_GAME_WIDTH;
    let gameHeight: number = DEFAULT_GAME_HEIGHT;

    if (SCALE_MODE === 'USER_SCALE') {
        let screenMetrics: Utils.ScreenMetrics = Utils.ScreenUtils.calculateScreenMetrics(gameWidth, gameHeight);

        gameWidth = screenMetrics.gameWidth;
        gameHeight = screenMetrics.gameHeight;
    }

    // There are a few more options you can set if needed, just take a look at Phaser.IGameConfig
    let gameConfig: Phaser.IGameConfig = {
        width: gameWidth,
        height: gameHeight,
        renderer: Phaser.AUTO,
        parent: parent,
        resolution: 1
    };

    App.app = new App(gameConfig, auth);
}

export function killApp() {

    if (App.app != null) {
        App.app.destroy();
        App.app = null;
    }
}

/*
window.onload = () => {
    let webFontLoaderOptions: any = null;
    let webFontsToLoad: string[] = GOOGLE_WEB_FONTS;

    if (webFontsToLoad.length > 0) {
        webFontLoaderOptions = (webFontLoaderOptions || {});

        webFontLoaderOptions.google = {
            families: webFontsToLoad
        };
    }

    if (Object.keys(Assets.CustomWebFonts).length > 0) {
        webFontLoaderOptions = (webFontLoaderOptions || {});

        webFontLoaderOptions.custom = {
            families: [],
            urls: []
        };

        for (let font in Assets.CustomWebFonts) {
            webFontLoaderOptions.custom.families.push(Assets.CustomWebFonts[font].getFamily());
            webFontLoaderOptions.custom.urls.push(Assets.CustomWebFonts[font].getCSS());
        }
    }

    if (webFontLoaderOptions === null) {
        // Just start the game, we don't need any additional fonts
        startApp();
    } else {
        // Load the fonts defined in webFontsToLoad from Google Web Fonts, and/or any Local Fonts then start the game knowing the fonts are available
        webFontLoaderOptions.active = startApp;

        WebFontLoader.load(webFontLoaderOptions);
    }
};*/
