import { ShapeSpawner } from './../../core/ShapeSpawner';
import { Model } from './../../core/model/Model';
import { PositiveAnimation } from './../..//sprites/feedback/PositiveAnimation';
import { DomUtils } from './../..//utils/utils';
import { PoissonDiskSimple } from './../..//core/math/PoissonDiskSimple';
import { Pallete } from './../..//core/Pallete';
import { Shape } from './../..//view/Shape';
import { PlayMachine } from './../..//core/PlayMachine';
import { ShapeVO } from './../..//core/vo/ShapeVO';
import { GameMode } from './../..//enum/GameMode';
import * as Assets from '../../assets';
import * as AssetUtils from '../../utils/assetUtils';
import State from '../../core/State';
import SFX from '../../utils/SFX';
import Music from '../../utils/Music';

import Game from '../../states/Game';
import SwipeGesture from '../../input/SwipeGesture';
import InputConfig from '../../input/InputConfig';
import { InputMode } from '../../enum/InputMode';


export default class TutorialGame extends State {

    private static CLICK_THRESHOLD: number = 40;

    private _target: Shape;
    private _shapes: Phaser.Group;

    //feedback
    private _positiveAnimation: PositiveAnimation;

    //touch input
    private _swipeGesture:SwipeGesture;

    public create(): void {

        //block native behaviour
        this.game.canvas.oncontextmenu = function (e) { e.preventDefault(); }

        this.spawnObjects();

        this._positiveAnimation = new PositiveAnimation(this.game);
        this.game.add.existing(this._positiveAnimation);

        this.addInputs();
    }

    private addInputs(): void {

        if(this.model.mode == GameMode.Dummy)
        {

            if(InputConfig.Mode == InputMode.Touch) {
                this._swipeGesture = new SwipeGesture(this.game);
                this._swipeGesture.ON_SWIPE.addOnce(this.onRightClick, this);
            }else{
                this.game.input.mousePointer.rightButton.onDown.addOnce(this.onRightClick, this);
            }

        }else{
            var btn = this.game.add.button(0, 0);
            Game.GAME_CLICK_INPUT_HANDLER = btn.input;
            btn.width = this.game.width;
            btn.height = this.game.height;
            btn.onInputUp.addOnce(this.onLeftClick, this);
        }
    }

    private addBorder(): void {
        this.game.add.sprite(0, 0, Assets.Images.ImagesTestBorder.getName(), null);
    }

    shutdown(): void {
        this.removeInputs();
        super.shutdown(this.game);
    }

    private removeInputs(): void {
        //remove inputs
        this.game.input.mousePointer.leftButton.onDown.removeAll();
        this.game.input.mousePointer.rightButton.onDown.removeAll();

        if(this._swipeGesture) this._swipeGesture.ON_SWIPE.removeAll();

    }

    private spawnObjects(): void {
        this._shapes = this.game.add.group();

        var spread: number = DomUtils.getQueryVariable("spread");
        if (spread == 0) spread = this.model.spread;
        this.createPoissonDisk(1920, 1080, Math.floor(spread as number));
    }

    private onRightClick(): void {

        this.removeInputs();

        if (this.model.mode == GameMode.Dummy) {

            for (var i = 0; i < this._shapes.children.length; i++) {
                var shp = this._shapes.children[i] as Shape;

                //IN
                var rnd = Phaser.Math.random(1.5, 2.5);
                var tween = this.game.add.tween(shp.scale).to({ x: rnd, y: rnd }, 400, Phaser.Easing.Back.Out, true, i * 10, 0, false)
                    .chain(this.game.add.tween(shp.scale).to({ x: 0, y: 0 }, 400, Phaser.Easing.Back.In, false));
            }

            this.onWin(2);

        } else {
            this.onLose();
        }
    }

    private onLeftClick(): void {

        this.removeInputs();

        if (this.model.mode != GameMode.Dummy) {

            if (this.clickedTarget(this.game.input.position) == true) {


                for (var i = 0; i < this._shapes.children.length; i++) {
                    var shp = this._shapes.children[i] as Shape;

                    //TARGET
                    if (shp == this._target) {
                        var rnd = Phaser.Math.random(1.5, 2.5);
                        this.game.add.tween(shp.scale).to({ x: rnd, y: rnd }, 400, Phaser.Easing.Back.Out, true, 0, 0, false)
                            .chain(this.game.add.tween(shp.scale).to({ x: 0, y: 0 }, 400, Phaser.Easing.Back.In, false));
                    } else {
                        this.game.add.tween(shp.scale).to({ x: 0, y: 0 }, 400, Phaser.Easing.Back.Out, true, 400 + i * 10, 0, false)
                    }

                }

                this._shapes.bringToTop(this._target);
                this._positiveAnimation.Show(this._target.x, this._target.y);
              //  this.camera.flash(0xffffff, 500, true);
                this.onWin(2);
            } else {
                this.onLose();
            }
        } else {
            this.onLose();
        }
    }

    private onLose(): void {
        //this.game.camera.flash(0xea4256, 500);
        this.game.camera.shake(0.010, 800);

        this.transition("tutorialguide", 1.1);

        for (var i = 0; i < this._shapes.children.length; i++) {
            var shp = this._shapes.children[i] as Shape;
            var startAngles = Phaser.Math.random(0, -30);
            var endAngles = Phaser.Math.random(0, 30);
            shp.angle = startAngles;
            this.game.add.tween(shp).to({ angle: endAngles }, 50, null, true, i * 10, 10, true);
        }

        SFX.play("fail 6", 1);
        SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.RoundEndUnlucky.toString());
    }

    private onWin(transitionDelay: number = 0): void {

        SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.RoundEndCongratulations.toString());
        SFX.play("Positive_Feedback_A05");

        var tutorialComplete = this.model.tutorialLevelComplete(true);
        if (tutorialComplete == true) {
            this.transition("progressmap", transitionDelay);
        } else {

            if (this.model.tutorialStep == 0 || this.model.tutorialStep == 2) {
                this.transition("tutorialguide", transitionDelay);
            } else {
                this.transition("tutorialinstructions", transitionDelay);
            }
        }
    }

    private clickedTarget(click: Phaser.Point): boolean {
        return (Phaser.Point.distance(this._target.position, click, true) < Game.CLICK_THRESHOLD * 2);
    }

    private createPoissonDisk(width: number, height: number, radius: number): void {

        let pd = new PoissonDiskSimple(width - radius * 2, height - radius * 2, radius);
        let sample = null;
        let distraction: ShapeVO;
        this._target = null;
        var point: { x: number, y: number } = { x: 0, y: 0 };
        var distractionIndex = 0;
        while ((sample = pd.return())) {

            point.x = sample[0] + radius;
            point.y = sample[1] + radius;

            if (this._target == null && this.model.mode != GameMode.Dummy) {
                this._target = new Shape(this.game, point.x, point.y, this.model.target);
                this._target.scale.x = this._target.scale.y = (this._target.scale.x * this.model.shapesizescale);
                this._shapes.add(this._target);
            } else {

                if (this.model.distractions && this.model.distractions.length > 0) {
                    var shp = new Shape(this.game, point.x, point.y, this.model.distractions[distractionIndex]);
                    shp.scale.x = shp.scale.y = (shp.scale.x * this.model.shapesizescale);
                    this._shapes.add(shp);

                    distractionIndex++;

                    //loop
                    if (distractionIndex > this.model.distractions.length - 1) distractionIndex = 0;
                }
            }
        }
    }

    rndf(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min)) + min;
    }
}
