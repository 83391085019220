import * as Assets from '../assets';
import App from '../app';
import Boot from './../states/Boot';
import { ENGINE_METHOD_DIGESTS } from 'constants';



export class ClosePanel extends Phaser.Group {

    private _okButton:Phaser.Button;

    constructor(g: Phaser.Game, parent:PIXI.DisplayObjectContainer) {
        super(g, parent);


        var fill = new Phaser.Graphics(this.game);
        fill.beginFill(0xffffff,.7);
        fill.drawRect(0,0,this.game.width, this.game.height);

        var bg = this.game.add.sprite(0,0, fill.generateTexture(),null, this);
        bg.width = g.width;;
        bg.height = g.height
        bg.inputEnabled = true;

        var panel = this.game.add.sprite(this.game.width/2, this.game.height/2, Assets.Atlases.AtlasesPreloader.getName(), Assets.Atlases.AtlasesPreloader.Frames.CloseDialog, this);
        panel.anchor.setTo(.5,.5);

        var k = Assets.Atlases.AtlasesPreloader.Frames.OkBtn;
        var okButton = this._okButton = this.game.add.button(0,0, Assets.Atlases.AtlasesPreloader.getName(), null, this, k, k, k, k, this);
        okButton.events.onInputDown.addOnce(this.OnOK, this);
        okButton.anchor.setTo(.5);
        okButton.x = panel.x + panel.width/2 - okButton.width/2;
        okButton.y = panel.y + okButton.height - 10;

        k = Assets.Atlases.AtlasesPreloader.Frames.CancelBtn;
        var cancelBtn = this.game.add.button(0,0, Assets.Atlases.AtlasesPreloader.getName(), this.OnCancel, this, k, k, k, k, this);
        cancelBtn.anchor.setTo(0, .5);
        cancelBtn.x = panel.x - panel.width/2;
        cancelBtn.y = panel.y + cancelBtn.height - 10;

        this.enabled = false;

        this.inputEnableChildren = true;
    }

    OnOK(): void {

        this._okButton.destroy();
        this._okButton = null;
        
        $('.ovrly').removeClass('visible').fadeOut();
        $('body').css('cursor', 'auto');        
        if (App.app != null) {

            if(Boot._persistantCloseBtn != null)
            {
                Boot._persistantCloseBtn.destroy();
                Boot._persistantCloseBtn = null;
            }

            App.app.destroy();
            App.app = null;
        }
    }

    OnCancel(): void {
       this.enabled = false;
    }

    public set enabled(value: boolean) {
        
        this.visible = value;

        if (value == true) {
            this.alpha = 0;
            this.game.add.tween(this).to({ alpha: 1 }, 400, Phaser.Easing.Cubic.Out, true);
        }
    }
}
