import * as Assets from '../assets';
import * as AssetUtils from '../utils/assetUtils';
import State from '../core/State';
import SFX from '../utils/SFX';
import Music from '../utils/Music';


export default class Preloader extends State {
    private preloadBarSprite: Phaser.Sprite = null;
    private preloadFrameSprite: Phaser.Sprite = null;

    public preload(): void {

        var g = this.game.add.graphics(0, 0);
        g.beginFill(0xe2e2e2, 1);
        g.drawRect(0, 0, this.game.width, this.game.height);

        this.preloadBarSprite = this.game.add.sprite(this.game.width / 2, this.game.height / 2, Assets.Atlases.AtlasesPreloader.getName(), Assets.Atlases.AtlasesPreloader.Frames.LoadingBar);
        this.preloadBarSprite.anchor.setTo(.5, 0.5);

        this.preloadFrameSprite = this.game.add.sprite(this.game.width / 2, this.game.height / 2, Assets.Atlases.AtlasesPreloader.getName(), Assets.Atlases.AtlasesPreloader.Frames.Loading);
        this.preloadFrameSprite.anchor.setTo(0.5);

        this.game.load.setPreloadSprite(this.preloadBarSprite);
        AssetUtils.Loader.loadAllAssets(this.game, this.waitForSoundDecoding, this);
    }

    private waitForSoundDecoding(): void {
        AssetUtils.Loader.waitForSoundDecoding(this.startGame, this);
    }

    private startGame(): void {

        var script = document.createElement('script');
        script.src = Assets.Scripts.ScriptsApi.getJS();
        document.getElementsByTagName('head')[0].appendChild(script);

        SFX.init(this.game);
        Music.init(this.game);

        this.game.camera.onFadeComplete.addOnce(this.loadTitle, this);
        this.game.camera.fade(0xe2e2e2, 700);
    }

    private loadTitle(): void {
        this.transition("title");
    }
}
