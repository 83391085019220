import * as Assets from '../assets';
import SFX from '../utils/SFX';
import Music from '../utils/Music';

export class MuteButton extends Phaser.Group {

    private _toggleState: boolean = true;

    private _button: Phaser.Button;
    private _onFrame: any;
    private _offFrame: any;

    public ON_TOGGLE = new Phaser.Signal();

    constructor(g: Phaser.Game, initialState:boolean) {
        super(g);

        this._toggleState = initialState;

        this._onFrame = Assets.Atlases.AtlasesEyelander.Frames.MuteOn;
        this._offFrame = Assets.Atlases.AtlasesEyelander.Frames.MuteOff;

        this._button = this.game.add.button(0, 0, Assets.Atlases.AtlasesEyelander.getName(), this.onToggle, this, this._onFrame, this._onFrame, this._onFrame, this._onFrame, this);
        this._button.anchor.setTo(.5);

        this.mute = !this._toggleState;
    }

    private onToggle(): void {
        this._toggleState = !this._toggleState;
        if (this._toggleState == true) SFX.play("Button Click 1");
         this.mute = !this._toggleState;
        if (this._toggleState == false) SFX.play("Button Click 5");

        this.ON_TOGGLE.dispatch(this._toggleState);
    }

    private set mute(val: boolean) {
        if (val == true) {
            this._button.setFrames(this._onFrame, this._onFrame, this._onFrame, this._onFrame);
           // this.game.sound.mute = true;
            this._button.anchor.y = .425;
        } else {
            this._button.setFrames(this._offFrame, this._offFrame, this._offFrame, this._offFrame);
           // this.game.sound.mute = false;
            this._button.anchor.y = .5;
        }
    }
}
