import { SessionVO } from './../core/vo/SessionVO';
import { ShapeVO } from './../core/vo/ShapeVO';
export class API {

    public static auth: any = null;
    public static ON_SERVER_UPDATED: Phaser.Signal = new Phaser.Signal();

    public static GetUser(onSuccess: (data: any, status: string, xhr: any) => void, onFailure: (data: any) => void): any {

        var c1: any;
        var c2: any;
        var c3: any;

        var Base64: any = { _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=", encode: function (e: any) { var t: string = ""; var n: any, r, i, s, o, u, a; var f = 0; e = Base64._utf8_encode(e); while (f < e.length) { n = e.charCodeAt(f++); r = e.charCodeAt(f++); i = e.charCodeAt(f++); s = n >> 2; o = (n & 3) << 4 | r >> 4; u = (r & 15) << 2 | i >> 6; a = i & 63; if (isNaN(r)) { u = a = 64 } else if (isNaN(i)) { a = 64 } t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a) } return t }, decode: function (e) { var t = ""; var n, r, i; var s, o, u, a; var f = 0; e = e.replace(/[^A-Za-z0-9\+\/\=]/g, ""); while (f < e.length) { s = this._keyStr.indexOf(e.charAt(f++)); o = this._keyStr.indexOf(e.charAt(f++)); u = this._keyStr.indexOf(e.charAt(f++)); a = this._keyStr.indexOf(e.charAt(f++)); n = s << 2 | o >> 4; r = (o & 15) << 4 | u >> 2; i = (u & 3) << 6 | a; t = t + String.fromCharCode(n); if (u != 64) { t = t + String.fromCharCode(r) } if (a != 64) { t = t + String.fromCharCode(i) } } t = Base64._utf8_decode(t); return t }, _utf8_encode: function (e) { e = e.replace(/\r\n/g, "\n"); var t = ""; for (var n = 0; n < e.length; n++) { var r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r) } else if (r > 127 && r < 2048) { t += String.fromCharCode(r >> 6 | 192); t += String.fromCharCode(r & 63 | 128) } else { t += String.fromCharCode(r >> 12 | 224); t += String.fromCharCode(r >> 6 & 63 | 128); t += String.fromCharCode(r & 63 | 128) } } return t }, _utf8_decode: function (e) { var t = ""; var n = 0; var r: any = c1 = c2 = 0; while (n < e.length) { r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r); n++ } else if (r > 191 && r < 224) { c2 = e.charCodeAt(n + 1); t += String.fromCharCode((r & 31) << 6 | c2 & 63); n += 2 } else { c2 = e.charCodeAt(n + 1); c3 = e.charCodeAt(n + 2); t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63); n += 3 } } return t } };
        /* $.ajax({
             type: 'GET',
             url: "http://www.d2test.co.uk/eyelander/wp-json/chewbacca-api/v1/getuser",
             crossDomain: true,
             dataType: 'json',
             beforeSend: function (xhr) {
                 xhr.setRequestHeader('Authorization', 'Basic ' + Base64.encode('d2admin:n0mMaCHhIrk5'));
             },
             success: onSuccess,
             error: onFailure
         });*/

        $.ajax({
            type: 'GET',
            url: API_ENDPOINT,
            crossDomain: true,
            dataType: 'json',
            data: {
                g_nonce: API.auth
            },
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', API.auth);
            },
            success: onSuccess,
            error: onFailure
        });





    }

    public static SendData(data: { target: ShapeVO, distractions: ShapeVO[], playthrough: number, session: SessionVO, success: boolean, targetPos: { x: number, y: number }, time: { remaining: number, limit: number }, difficulty: number }): void {

        console.log("sending data to API: " + JSON.stringify(data));

        var c1: any;
        var c2: any;
        var c3: any;

        var Base64: any = { _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=", encode: function (e) { var t = ""; var n, r, i, s, o, u, a; var f = 0; e = Base64._utf8_encode(e); while (f < e.length) { n = e.charCodeAt(f++); r = e.charCodeAt(f++); i = e.charCodeAt(f++); s = n >> 2; o = (n & 3) << 4 | r >> 4; u = (r & 15) << 2 | i >> 6; a = i & 63; if (isNaN(r)) { u = a = 64 } else if (isNaN(i)) { a = 64 } t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a) } return t }, decode: function (e) { var t = ""; var n, r, i; var s, o, u, a; var f = 0; e = e.replace(/[^A-Za-z0-9\+\/\=]/g, ""); while (f < e.length) { s = this._keyStr.indexOf(e.charAt(f++)); o = this._keyStr.indexOf(e.charAt(f++)); u = this._keyStr.indexOf(e.charAt(f++)); a = this._keyStr.indexOf(e.charAt(f++)); n = s << 2 | o >> 4; r = (o & 15) << 4 | u >> 2; i = (u & 3) << 6 | a; t = t + String.fromCharCode(n); if (u != 64) { t = t + String.fromCharCode(r) } if (a != 64) { t = t + String.fromCharCode(i) } } t = Base64._utf8_decode(t); return t }, _utf8_encode: function (e) { e = e.replace(/\r\n/g, "\n"); var t = ""; for (var n = 0; n < e.length; n++) { var r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r) } else if (r > 127 && r < 2048) { t += String.fromCharCode(r >> 6 | 192); t += String.fromCharCode(r & 63 | 128) } else { t += String.fromCharCode(r >> 12 | 224); t += String.fromCharCode(r >> 6 & 63 | 128); t += String.fromCharCode(r & 63 | 128) } } return t }, _utf8_decode: function (e) { var t = ""; var n = 0; var r = c1 = c2 = 0; while (n < e.length) { r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r); n++ } else if (r > 191 && r < 224) { c2 = e.charCodeAt(n + 1); t += String.fromCharCode((r & 31) << 6 | c2 & 63); n += 2 } else { c2 = e.charCodeAt(n + 1); c3 = e.charCodeAt(n + 2); t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63); n += 3 } } return t } };
        
        /*$.ajax({
            type: 'POST',
            url: "http://www.d2test.co.uk/eyelander/wp-json/chewbacca-api/v1/postdata",
            crossDomain: true,
            data: {
                g_data: JSON.stringify(data)
            },
            dataType: 'json',
            beforeSend: function (xhr) {
                xhr.setRequestHeader('Authorization', 'Basic ' + Base64.encode('d2admin:n0mMaCHhIrk5'));
            },
            success: function (data, txtStatus, xhr) {
                API.ON_SERVER_UPDATED.dispatch(data);
                console.log(data);
                console.log(xhr.status);
            },
            error: function (data) {
                console.log(data);
            }
        });*/

        $.ajax({
            type: 'POST',
            url: "https://www.eyelander.co.uk/wp-json/chewbacca-api/v1/postdata",
            crossDomain: true,            
            data: {
                g_data: JSON.stringify(data),
                g_nonce : API.auth
            },
            dataType: 'json',
            beforeSend: function (xhr) {
                xhr.setRequestHeader( 'X-WP-Nonce', API.auth );
            },
            success: function (data, txtStatus, xhr) {
                API.ON_SERVER_UPDATED.dispatch(data);
                console.log(data);
                console.log(xhr.status);
            },
            error: function (data) {
                console.log(data);
            }
        });

    }

}