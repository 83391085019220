import { ArrayUtils } from './../utils/utils';
import { ShapeVO } from './vo/ShapeVO';
import { Pallete } from './Pallete';
import { Form } from "../enum/Form";

export class ShapeSpawner {

    public static SpawnSet(count: number, contrasted: boolean = false): Array<ShapeVO> {
        var set: Array<ShapeVO> = new Array<ShapeVO>();
        var seed: Seed = new Seed();
        var target: ShapeVO = null;

        //avoid accidental multiple contrasting objects (not possible as seed is too small)
        count = (contrasted == true) ? 2 : count;
        
        //cap it at 4 to stop algo breaking
        count = (count > 4) ? 4 : count; 
        
        //setup some unique indexes so we can shuffle them
        var uniqueIndexes = ArrayUtils.Shuffle([0,1,2]);

        for (var i = 0; i < count; i++) {
            if (set.length == 0) {

                target = ShapeSpawner.spawnSeed(seed);
                while (ShapeSpawner.isValid(target) == false) {
                    seed = new Seed();
                    target = ShapeSpawner.spawnSeed(seed);
                }

                //Add Target Shape
                set.push(target);

            } else {
                set.push(ShapeSpawner.spawnDirivitive(target, seed, contrasted, uniqueIndexes[i-1]));
            }
        }

        return set;
    }

    private static isValid(shape: ShapeVO): boolean {
        //little helper to find invalid shapes
        if (shape.data.form == Form.Circle && shape.uniqueness.indexOf("rotation") > -1) return false;

        return true;
    }

    private static spawnSeed(seed: Seed): ShapeVO {

        var shp: ShapeVO = new ShapeVO();
        shp.uniqueness.push("form");
        shp.uniqueness.push("tint");

        var secondary: Array<string> = new Array<string>();
        secondary.push("rotation", "flash", "scale");

        shp.uniqueness.push(secondary[Math.floor(Math.random() * secondary.length)]);

        for (var i = 0; i < shp.uniqueness.length; i++) {
            var prop = seed.getRandom(shp.uniqueness[i]);
            if (prop != null) {
                shp.data[shp.uniqueness[i]] = prop;
            } else {
                console.error("there was an error spawning seed object!");
            }
        }

        return shp;
    }

    private static spawnDirivitive(shape: ShapeVO, seed: Seed, contrasted: boolean = false, uniqueIndex:number = 0): ShapeVO {

        //Clone shape
        var derivitive: ShapeVO = new ShapeVO();
        derivitive.data = JSON.parse(JSON.stringify(shape.data));
        derivitive.uniqueness = shape.uniqueness;

        if (contrasted == true) {
            //Change Form
            var form = seed.getRandom("form");
            if (form != null) {
                derivitive.data.form = form;
            } else {
                console.error("no forms found in seed object");
            }

            //Change Tint
            var tint = seed.getRandom("tint");
            if (tint != null) {
                derivitive.data.tint = tint;
            } else {
                console.error("no tints found in seed object");
            }

            //Change Secondary Feature
            var uKey:string = null;
            for(var i = 0; i < shape.uniqueness.length; i++)
            {
                if(shape.uniqueness[i] != "tint" && shape.uniqueness[i] != "form") uKey = shape.uniqueness[i];
            }

            var uFeature = seed.getRandom(uKey);
            if(uFeature != null)
            {
                derivitive.data[uKey] = uFeature;
            }else{
                console.error("no " + uKey + " features found in seed object.  This could be because you are spawning more than one distractor objects...");
            }

        } else {
            
            var done = false;
            var tries: number = 0;
            while (!done) {

                //grab relevant unique index
                var key = shape.uniqueness[uniqueIndex];

                var prop = seed.getRandom(key);

                if (prop != null) {
                    derivitive.data[key] = prop;
                    done = true;
                } else {
                    tries++;
                    if (tries > 100) {
                        console.error("loop stuck...");
                        return null;
                    }
                }
            }
        }

        return derivitive;
    }

    private static spawnEasy(shape: ShapeVO, seed: Seed): ShapeVO {

        //Clone shape
        var derivitive: ShapeVO = new ShapeVO();
        derivitive.data = JSON.parse(JSON.stringify(shape.data));
        derivitive.uniqueness = shape.uniqueness;

        var done = false;
        var tries: number = 0;
        while (!done) {
            var key = shape.uniqueness[Math.floor(Math.random() * shape.uniqueness.length)];
            var prop = seed.getRandom(key);

            if (prop != null) {
                derivitive.data[key] = prop;
                done = true;
            } else {
                tries++;
                if (tries > 100) {
                    console.error("loop stuck...");
                    return null;
                }
            }
        }

        return derivitive;
    }
}



export class Seed {

    public data: {
        form: Array<Form>,
        tint: Array<number>,
        rotation: Array<boolean>,
        scale: Array<number>,
        flash: Array<boolean>
    } = {
        form: [],
        tint: [],
        rotation: [],
        scale: [],
        flash: []
    }

    constructor() {
        this.data.form = [Form.Circle, Form.Crescent, Form.Rectangle, Form.Square, Form.Triangle];
        this.data.tint = [Pallete.GREEN, Pallete.ORANGE, Pallete.PINK, Pallete.BLUE];
        this.data.rotation = [true, false];
        this.data.scale = [0.75, 1.25];
        this.data.flash = [true, false];
    }

    //removes and returns a random element from data
    public getRandom(key: string): any {

        if (this.data[key] != null && this.data[key].length > 0) {
            var rnd = Math.floor(Math.random() * this.data[key].length);
            var set: Array<any> = this.data[key];
            var prop: any = set.splice(rnd, 1)[0];

            return prop;

        } else {
            return null;
        }
    }
}