import * as Assets from '../assets';
import SFX from '../utils/SFX';

export class StandardUI extends Phaser.Group {

    public ON_BACK: Phaser.Signal = new Phaser.Signal();
    public ON_PLAY: Phaser.Signal = new Phaser.Signal();

    constructor(g: Phaser.Game, bottomOfScreen:boolean = false) {
        super(g);

        var y = bottomOfScreen == true ? (this.game.height-60) : this.game.height/2;
        var yA = bottomOfScreen == true ? 1 : .5;

        var k = "play_btn.png";
        var playBtn = this.game.add.button(this.game.width - 60, y, Assets.Atlases.AtlasesEyelander.getName(), this.OnPlay, this, k, k, k, k, this);
        playBtn.fixedToCamera = true;
        playBtn.anchor.setTo(1, yA);

        var k = "back-btn.png";
        var backBtn = this.game.add.button(60, y, Assets.Atlases.AtlasesEyelander.getName(), this.OnBack, this, k, k, k, k, this);
        backBtn.fixedToCamera = true;
        backBtn.anchor.setTo(0, yA);
    }

    OnPlay(): void {
        SFX.play("Button Click 4");
        this.ON_PLAY.dispatch();
    }

    OnBack(): void {
        SFX.play("Button Click 3");
        this.ON_BACK.dispatch();
    }

    public set enabled(value: boolean) {
        
        this.visible = value;

        if (value == true) {
            this.alpha = 0;
            this.game.add.tween(this).to({ alpha: 1 }, 1000, Phaser.Easing.Cubic.Out, true, 300);
        }
    }
}
