import { Hand } from './../../enum/Hand';
import { ShapeSize } from './../../enum/ShapeSize'
import { GameSpeed } from './../../enum/GameSpeed'
import { CursorSize } from './../../enum/CursorSize'

export class SessionVO
{
    public unlocked: number;
    public weighting: number;
    public consecutivelosses: number;
    public shapesize: ShapeSize;
    public gamespeed: GameSpeed;
    public cursorsize: CursorSize;
    public hand:Hand;

    constructor(unlocked:number = 0, weighting:number = 0, concecutivelosses:number = 0, shapesize:ShapeSize = ShapeSize.Medium, gamespeed:GameSpeed = GameSpeed.Normal, cursorsize:CursorSize = CursorSize.Regular, hand:Hand = Hand.Left)
    {
        this.unlocked = unlocked;
        this.weighting = weighting;
        this.consecutivelosses = concecutivelosses;
        this.shapesize = shapesize;
        this.gamespeed = gamespeed;
        this.cursorsize = cursorsize;
        this.hand = hand;
    }
}