import * as Assets from '../../assets';
import * as AssetUtils from '../../utils/assetUtils';

export class PositiveAnimation extends Phaser.Sprite {
    
    public ON_COMPLETE:Phaser.Signal = new Phaser.Signal();
    
    constructor(game:Phaser.Game)
    {
        super(game, 0,0, Assets.Atlases.AtlasesEyelander.getName(), "positive_00000.png");
        this.animations.add("feedback", Phaser.Animation.generateFrameNames("positive_", 0, 29, ".png", 5), 29, false);
        this.visible = false;

        this.anchor.setTo(.5,.5);
    }

    public Show(x:number, y:number):void
    {
        this.x = x;
        this.y = y;
        this.visible = true;

        this.animations.play("feedback",29, false, true).onComplete.addOnce(this.onFeedbackComplete, this);
    }

    private onFeedbackComplete():void
    {
        this.ON_COMPLETE.dispatch();
        this.visible = false;
    }
}