import { Hand } from './../../enum/Hand';
import { SessionVO } from './../vo/SessionVO';
import { ShapeSpawner } from './../ShapeSpawner';
import { ShapeVO } from './../vo/ShapeVO';
import { GameMode } from './../../enum/GameMode'
import { ShapeSize } from './../../enum/ShapeSize'
import { GameSpeed } from './../../enum/GameSpeed'
import { CursorSize } from './../../enum/CursorSize'
export class Model {

    private _level: ShapeVO[];
    private _distractions: Array<ShapeVO>;
    private _mode: GameMode;

    private _contrasted: boolean = false;

    private _tutorialStep: number = 1;
    private _playthrough: number = 0;

    private _session:SessionVO;

    constructor(session:SessionVO = null, playthrough: number) {

        if (playthrough != null) this._playthrough = playthrough;

        if (session != null) {
            this._session = session;
            if (this._session.gamespeed == undefined) this._session.gamespeed = GameSpeed.Normal; //while server is broken
            if (this._session.cursorsize == undefined) this._session.cursorsize = CursorSize.Regular; //while server is broken
        } else {
            this._session = new SessionVO();
        }
    }

    public getTotalStagesForLevel(level: number): number {
        level = Math.floor(level);
        if (level == 0) return 0;
        else if (level == 1) return 1;
        else if (level == 2) return 3;
        else if (level == 3) return 7;
        else return 11;
    }

    public tutorialLevelComplete(success: boolean): boolean {
        if (success) {
            this._tutorialStep++;
            var tutorialSteps = HIDDEN_SHAPES ? 2 : 1;
            if (this._tutorialStep > tutorialSteps) return true;
        }

        return false;
    }

    //returns whether world has been completed
    public levelComplete(success: boolean): boolean {

        var worldComplete = false;

        //Progression
        if (this.playingFinalStage == true) {
            this._session.unlocked = Math.floor(this.unlocked) + 1;
            worldComplete = true;

            // reset once conjunctions are introduced
            if (this.level == 4) this._session.weighting = 0;

        } else {
            this._session.unlocked = Number((this._session.unlocked + 0.01).toFixed(2));
        }

        //Weighting only effected on newest unlocked level
        if (success == true) {

            this._session.consecutivelosses = 0;

            if (this.weighting < 10) {
                this._session.weighting = 10;
            } else if (this.weighting <= 90) {
                this._session.weighting += 10;
            }

        } else if (!success) {

            this._session.consecutivelosses++;
            if (this._session.consecutivelosses >= 3) {
                this._session.weighting = 0;
            }

        }

        return worldComplete;
    }

    private get playingFinalStage(): boolean {
        return (this.stage >= this.getTotalStagesForLevel(this.unlocked))
    }

    private seedConjunction(): GameMode {
        if (this.weighting == 0) {
            return GameMode.Simple;
        } else {
            var seed = this.getConjunctionPercentageOdds() + this._session.weighting;
            var rnd = Math.random() * 100;
            if (rnd <= seed) return GameMode.Conjunction;
            else return GameMode.Simple;
        }
    }

    private getConjunctionPercentageOdds(): number {

        var odds = 10;

        switch (this.level) {
            case 5:
                odds = 10;
                break;
            case 6:
                odds = 20;
                break;
            case 7:
                odds = 30;
                break;

            case 8:
                odds = 40;
                break;

            case 9:
                odds = 50;
                break;

            case 10:
                odds = 60;
                break;

            case 11:
                odds = 90;
                break;

        }

        return odds;
    }

    private setMode(): void {

        var rnd = Math.random() * 100;

        //Is it a Simple level?
        if (this.level < 4) {
            this._mode = GameMode.Simple;
            //Is it a Dummy level?
        } else if (this.level == 5 && this.stage == 0 && HIDDEN_SHAPES == true || this.level >= 4 && rnd <= 10 && HIDDEN_SHAPES == true) {
            this._mode = GameMode.Dummy;
            //Or is it seeded?
        } else {
            this._mode = this.seedConjunction();
        }
    }

    get difficulty(): number {

        var d = 1;
        switch (this._mode) {

            case GameMode.Simple:
                if (this._contrasted) d = 1;
                else d = 2
                break;

            case GameMode.Dummy:
                d = 3;
                break;
            case GameMode.Conjunction:
                d = 4;
                break;
        }

        return d;
    }

    get mode(): GameMode {
        return this._mode;
    }

    get world(): number {
        return Math.floor(this.unlocked / 3);
    }

    get level(): number {
        return Math.floor(this.unlocked);
    }

    get stage(): number {
        return Number((this.unlocked - Math.floor(this.unlocked)).toFixed(2)) * 100;
    }

    get unlocked(): number {
        return this._session.unlocked;
    }

    get weighting(): number {
        return this._session.weighting;
    }

    get playthrough(): number {
        return this._playthrough;
    }

    set playthrough(val:number)
    {
        this._playthrough = val;
    }

    public resetSession():void
    {
        this._session.unlocked = 0;
        this._session.weighting = 0;
        this._session.consecutivelosses = 0;
    }

    public getStarRating(remaining: number): number {
        var rating = 3;
        var percent: number = (remaining / this.roundtime) * 100;

        if (percent >= 70) {
            rating = 3;
        }else if(percent>= 40)
        {
            rating = 2;
        }else{
            rating = 1;
        }

        return rating;
    }

    get roundtime(): number {
        if (this.weighting <= 40) return 20000 * this.gamespeedValue;
        else if (this.weighting <= 80) return 15000 * this.gamespeedValue;
        else if (this.weighting > 80) return 10000 * this.gamespeedValue;
    }

    get shapesize(): ShapeSize {
        return this._session.shapesize;
    }

    get shapesizescale(): number {
        var scale: number = 1;

        switch (this.shapesize) {
            case ShapeSize.Small:
                scale = .75;
                break;
            case ShapeSize.Large:
                scale = 1.25;
                break;
        }

        return scale;
    }

    set hand(hand: Hand) {
        this._session.hand = hand;
    }

    get hand(): Hand {
        return this._session.hand;
    }

    set cursorsize(size: CursorSize) {
        this._session.cursorsize = size;
    }

    get cursorsize(): CursorSize {
        return this._session.cursorsize;
    }

    set shapesize(size: ShapeSize) {
        this._session.shapesize = size;
    }

    set gamespeed(speed: GameSpeed) {
        this._session.gamespeed = speed;
    }

    get gamespeed(): GameSpeed {
        return this._session.gamespeed;
    }

    get gamespeedValue(): number {
        switch (this.gamespeed) {
            case GameSpeed.Slow:
                return 2;
            case GameSpeed.Normal:
                return 1;
            case GameSpeed.Fast:
                return .5;
        }

        return 1;
    }

    get concecutivelosses(): number {
        return this._session.consecutivelosses;
    }

    get spread(): number {
        var d = 80;
        if (this.weighting < 0) d *= .75;
        else if (this.weighting > 0) d *= 1;

        return 200;
    }

    get contrasted(): boolean {
        return this._contrasted;
    }

    get tutorialStep(): number {
        return this._tutorialStep;
    }

    public newTutorial(): void {
        if (this.tutorialStep == 0) {
            this._mode = GameMode.Simple;
            this._level = ShapeSpawner.SpawnSet(1);
            this._distractions = this._level.concat();
            this._distractions.shift();
        } else if (this.tutorialStep == 1) {
            this._mode = GameMode.Simple;
            this._level = ShapeSpawner.SpawnSet(2, true);
            this._distractions = this._level.concat();
            this._distractions.shift();
        } else {
            this._mode = GameMode.Dummy;
            this._level = ShapeSpawner.SpawnSet(2);
            this._distractions = this._level.concat();
            this._distractions.shift();
        }
    }

    public newLevel(forcedDistractions?: number): void {

        this.setMode();

        if (forcedDistractions != null && forcedDistractions > 0) {
            this._level = ShapeSpawner.SpawnSet(forcedDistractions + 1);
        } else {
            this._contrasted = (this.mode == GameMode.Simple && this.weighting <= 50);
            this._level = ShapeSpawner.SpawnSet(this.distractionCount, this.contrasted);
        }

        this._distractions = this._level.concat();
        this._distractions.shift();
    }

    private get distractionCount(): number {
        if (this.mode == GameMode.Simple) return 2;
        else if (this.mode == GameMode.Dummy) return 3;
        else return 4;
    }

    public get target(): ShapeVO {
        if (this._level && this._level.length > 0) return this._level[0];
        return null;
    }

    public get distractions(): Array<ShapeVO> {
        return this._distractions;
    }

    public getAPIFormattedData(success:boolean = false, time:number = 0, targetX:number = 0, targetY:number = 0):any
    {
        return {
            target: this.target,
            distractions: this.distractions,
            playthrough: this.playthrough,
            session: { unlocked: this.unlocked, weighting: this.weighting, consecutivelosses: this.concecutivelosses, shapesize: this.shapesize, gamespeed:this.gamespeed, cursorsize:this.cursorsize},
            success: success,
            targetPos: {x:targetX, y:targetY},
            time: { remaining: time, limit: this.roundtime},
            difficulty: this.difficulty
        }
    }
}