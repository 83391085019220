import * as Assets from '../assets';

export default class Music
{
    private static _tracks:Phaser.Sound[];
    private static _current:Phaser.Sound;

    public static init(game:Phaser.Game)
    {
        Music._tracks = [];
        Music._tracks.push(game.add.audio(Assets.Audio.AudioBeach.getName()));
        Music._tracks.push(game.add.audio(Assets.Audio.AudioCanyon.getName()));
        Music._tracks.push(game.add.audio(Assets.Audio.AudioForest.getName()));
        Music._tracks.push(game.add.audio(Assets.Audio.AudioMenu.getName()));
        Music._tracks.push(game.add.audio(Assets.Audio.AudioVolcano.getName()));
    }

    public static playWorldMusic(world:number, volume:number = 1):void
    {
        var key:string = "";
        switch(world)
        {
            case 0:
                key = Assets.Audio.AudioBeach.getName();
            break;

            case 1: 
                key = Assets.Audio.AudioForest.getName();
            break;

            case 2:
                key = Assets.Audio.AudioCanyon.getName();
            break;
            case 3:
            key = Assets.Audio.AudioVolcano.getName();
            break;
        }

        Music.play(key, volume);

    }

    public static play(key:string, volume:number = 1):void
    {
        var track = Music.getTrack(key);
        if(Music._current != track)
        {
            if(Music._current != null)
            {
                Music._current.fadeOut(1000);
            }

            track.volume = volume;
            track.fadeIn(1000, true);
            Music._current = track;
            
        }else{
            Music._current.volume = volume;
        }
    }

    private static stopAll():void
    {
        for(var i = 0; i < Music._tracks.length; i++)
        {
            Music._tracks[i].stop();
        }
    }

    private static getTrack(key:string):Phaser.Sound
    {
        for(var i = 0; i < Music._tracks.length; i++)
        {
            if(key == Music._tracks[i].key) return Music._tracks[i];
        }

        return null;
    }

    public static mute(val:boolean):void
    {
        for(var i = 0; i < Music._tracks.length; i++)
        {
            Music._tracks[i].mute = val;
        }
    }
}