export class Pallete
{
    public static PINK:number = 0xE9528D;
    public static GREEN:number = 0x6FAA27;
    public static ORANGE:number = 0xEE7D00;
    public static BLUE:number = 0x0094DA;

    private static TINTS:any = 
    {
        PINK: Pallete.PINK,
        GREEN: Pallete.GREEN,
        ORANGE: Pallete.ORANGE,
        BLUE: Pallete.BLUE
    }

    public static GetRandom(exclude:number = -1):number
    {
        var keys = Object.keys(Pallete.TINTS);
        var random = Pallete.TINTS[keys[ keys.length * Math.random() << 0]];

        //exclude?
        if(Pallete.TINTS[Pallete.GetName(exclude)] != null)
        {
            if(random == exclude)
            {
                return Pallete.GetRandom(exclude);
            }
        } 
        
        return random;
    }

    public static GetName(value:number):string
    {
       for(var key in Pallete.TINTS){
         if(Pallete.TINTS[key]==value) return key;
      }
    }
}