import * as Assets from '../assets';
import Sound from '../states/Sound';

export default class SFX
{
    private static _sfx:Phaser.AudioSprite;

    private static _sfxKeys:string[] = ["Button Click 1", "Button Click 2", "Button Click 3", "Button Click 4", "button", "fail 6", "lose", "Positive_Feedback_A01", "Positive_Feedback_A02", "Positive_Feedback_A03", "Positive_Feedback_A04", "Positive_Feedback_A05", "Positive_Feedback_A06", "win"]

    public static init(game:Phaser.Game)
    {
        SFX._sfx = game.add.audioSprite(Assets.Audiosprites.AudiospritesSfx.getName());
    }

    public static play(key:string, volume:number = 1):Phaser.Sound
    {
        if(SFX._sfx != null)
        {
            var enabled = (SFX._sfxKeys.indexOf(key) >= 0 && Sound.STATE.sfx == true || SFX._sfxKeys.indexOf(key) < 0 && Sound.STATE.vo == true)
            var snd = enabled ? SFX._sfx.play(key, volume) : SFX._sfx.play(key, 0);
            return snd;
        }
    }
}