import * as Assets from '../assets';
import * as AssetUtils from '../utils/assetUtils';
import { ShapeVO } from './../core/vo/ShapeVO';
import { Form } from './../enum/Form';
export class Shape extends Phaser.Sprite
{
    private _vo:ShapeVO;
    private _flashing:boolean = false;

    constructor(game:Phaser.Game, x:number, y:number, vo:ShapeVO)
    {
        super(game, x, y, Assets.Atlases.AtlasesEyelander.getName(), "shape_" + Form[vo.data.form].toLowerCase() + ".png");
        this.anchor.setTo(.5);
        this.draw(vo);

        this._vo = vo;

        if(this._flashing)
        {
            this.alpha = 0;
            game.add.tween(this).to( { alpha: 1 }, 1000, Phaser.Easing.Linear.None, true, Math.random()*500, 250, true);
        }
    }

    private draw(vo:ShapeVO):void
    {
        this.tint = vo.data.tint;
        this.rotation = vo.data.rotation == 0 ? 0 : this.getRotation(vo.data.form);
        this.scale.setTo(vo.data.scale);
        this._flashing = vo.data.flash;
    }

    private getRotation(form:Form):number
    {
        switch(form)
        {
            case Form.Circle:
                return 0;
            case Form.Crescent:
                return 180;
            case Form.Rectangle:
                return 90;
            case Form.Square:
                return 45;
            case Form.Star:
                return 45;
            case Form.Triangle:
                return 30;
            default:
                return 0;
        }
    }
}