import { Model } from './model/Model';
import * as Assets from '../assets';
import { TouchBlip } from '../sprites/TouchBlip';
import InputConfig from '../input/InputConfig';
import { InputMode } from '../enum/InputMode';

export default class State extends Phaser.State {
    
    protected model: Model;
    private _blip:TouchBlip;

    public init(model: Model): void {
        this.model = model;

        this.addTouchBlip();
    }

    private addTouchBlip():void
    {
        this._blip = new TouchBlip(this.game);
        this.game.add.existing(this._blip);
        this.game.input.onTap.add(this.onTap, this);
    }

    private onTap():void
    {
        var x = this.game.input.activePointer.x;
        var y = this.game.input.activePointer.y;

        this._blip.onTouch(x,y);
        this.game.world.bringToTop(this._blip);
    }

    protected transition(key: string, delay?: number) {
        if (!delay) {
            this.game.state.start(key, true, false, this.model);

        } else {
            this.game.time.events.add(Phaser.Timer.SECOND * delay, function () {
                this.game.state.start(key, true, false, this.model);
            }, this);
        }

    }

    update():void
    {
        this._blip.visible = InputConfig.Mode == InputMode.Touch;
    }
}