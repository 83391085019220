import { Hand } from './../enum/Hand';
import { ShapeVO } from './../core/vo/ShapeVO';
import { Shape } from './../view/Shape';
import { API } from './../services/API';
import { Pallete } from './../core/Pallete';
import * as Assets from '../assets';
import State from '../core/State';
import SFX from '../utils/SFX';
import Music from '../utils/Music';

import { Form } from "../enum/Form";
import { ShapeSize } from "../enum/ShapeSize";
import { GameSpeed } from "../enum/GameSpeed";
import { CursorSize } from "../enum/CursorSize";
import { InputMode } from '../enum/InputMode';
import InputConfig from '../input/InputConfig';

export default class Settings extends State {

    shapes: Phaser.Group;
    private _small: Phaser.Button;
    private _medium: Phaser.Button;
    private _large: Phaser.Button;

    times: Phaser.Group;
    private _slow: Phaser.Button;
    private _normal: Phaser.Button;
    private _fast: Phaser.Button;

    cursors: Phaser.Group;
    private _little: Phaser.Button;
    private _regular: Phaser.Button;
    private _big: Phaser.Button;

    hands: Phaser.Group;
    private _left: Phaser.Button;
    private _right: Phaser.Button;

    inputs: Phaser.Group;
    private _mouse:Phaser.Button;
    private _touch:Phaser.Button;

    private BUTTON_X_PAD: number = 170;
    private BUTTON_Y_PAD: number = 180;

    public create(): void {

        var bg = this.game.add.sprite(0, 0, "Menu-BG");

        var header = this.game.add.bitmapText(0, 0, Assets.BitmapFonts.FontsVag.getName(), "Settings", 90);
        header.tint = 0xffffff;
        header.align = "center";
        header.x = this.game.width / 2;
        header.y = 100;
        header.anchor.setTo(.5, 0);

        var k = "back-btn.png";
        var backBtn = this.game.add.button(60, 60, Assets.Atlases.AtlasesEyelander.getName(), this.onBack, this, k, k, k, k);
        backBtn.fixedToCamera = true;
        backBtn.anchor.setTo(0, 0);

        var p = this.game.add.sprite(this.game.width / 2, this.game.height / 2 + 100, Assets.Images.ImagesSettingsPanel.getName());
        p.anchor.setTo(.5);

        this.buildLayout();

        Music.play(Assets.Audio.AudioMenu.getName());
    }

    protected buildLayout():void
    {

        if(InputConfig.TouchAvailable)
        {
            var s = this.addShapeSizeButtons();
            s.y = 310;
            s.x = -320;
    
            var t = this.addTimeButtons();
            t.y = 310
            t.x = 320;
    
            var h = this.addHandButtons();
            h.x = -475;//-320;
            h.y = 660
    
            var c = this.addCursorButtons();
            c.x = 0;
            c.y = 660;
    
            var m = this.addInputModeButtons();
            m.x = 475;
            m.y = 660;
    
            this.setInputMode(InputConfig.Mode);
        }else{
            
            var s = this.addShapeSizeButtons();
            s.y = 310;
            s.x = -320;
    
            var t = this.addTimeButtons();
            t.y = 310
            t.x = 320;
    
            var c = this.addCursorButtons();
            c.x = -320;
            c.y = 660;
    
            var h = this.addHandButtons();
            h.x = 320;
            h.y = 660
        }

        this.setSize(this.model.shapesize);
        this.setGameSpeed(this.model.gamespeed);
        this.setHand(this.model.hand);
        this.setCursor(this.model.cursorsize);
    }

    getBlock(scale: number, frame: any, callback: Function): Phaser.Button {

        var block = this.game.add.button(this.game.width / 2, 0, Assets.Atlases.AtlasesEyelander.getName(), callback, this, frame, frame, frame, frame);
        block.anchor.setTo(.5, .5);
        return block;
    }

    addShapeSizeButtons(): Phaser.Group {

        var container = this.game.add.group();

        this.shapes = this.game.add.group();
        container.add(this.shapes);

        var subheader1 = this.game.add.bitmapText(0, 0, Assets.BitmapFonts.FontsVag.getName(), "Shape Size", 45, container);
        subheader1.tint = 0xffffff;
        subheader1.align = "center";
        subheader1.x = this.game.width / 2;
        subheader1.anchor.setTo(.5, 0);

        this._medium = this.getBlock(1, Assets.Atlases.AtlasesEyelander.Frames.Ss2, this.onMedium);
        this._medium.x = this.game.width / 2;
        this._medium.y = this.BUTTON_Y_PAD;
        this.shapes.add(this._medium);

        this._small = this.getBlock(.75, Assets.Atlases.AtlasesEyelander.Frames.Ss1, this.onSmall);
        this._small.x = this._medium.x - this.BUTTON_X_PAD;
        this._small.y = this._medium.y;
        this.shapes.add(this._small);

        this._large = this.getBlock(1.25, Assets.Atlases.AtlasesEyelander.Frames.Ss3, this.onLarge);
        this._large.x = this._medium.x + this.BUTTON_X_PAD;
        this._large.y = this._medium.y;
        this.shapes.add(this._large);

        return container;
    }

    addTimeButtons(): Phaser.Group {
        var container = this.game.add.group();

        this.times = this.game.add.group();
        container.add(this.times);

        var subheader1 = this.game.add.bitmapText(0, 0, Assets.BitmapFonts.FontsVag.getName(), "Game Speed", 45, container);
        subheader1.tint = 0xffffff;
        subheader1.align = "center";
        subheader1.x = this.game.width / 2;
        subheader1.anchor.setTo(.5, 0);

        this._normal = this.getBlock(1, Assets.Atlases.AtlasesEyelander.Frames.Rs2, this.onNormal);
        this._normal.x = this.game.width / 2;
        this._normal.y = this.BUTTON_Y_PAD;
        this.times.add(this._normal);

        this._slow = this.getBlock(.75, Assets.Atlases.AtlasesEyelander.Frames.Rs1, this.onSlow);
        this._slow.x = this._normal.x - this.BUTTON_X_PAD;
        this._slow.y = this._normal.y;
        this.times.add(this._slow);

        this._fast = this.getBlock(1.25, Assets.Atlases.AtlasesEyelander.Frames.Rs3, this.onFast);
        this._fast.x = this._normal.x + this.BUTTON_X_PAD;;
        this._fast.y = this._normal.y;
        this.times.add(this._fast);

        return container;
    }

    addCursorButtons(): Phaser.Group {
        var container = this.game.add.group();

        this.cursors = this.game.add.group();
        container.add(this.cursors);

        var subheader1 = this.game.add.bitmapText(0, 0, Assets.BitmapFonts.FontsVag.getName(), "Cursor Size", 45, container);
        subheader1.tint = 0xffffff;
        subheader1.align = "center";
        subheader1.x = this.game.width / 2;
        subheader1.anchor.setTo(.5, 0);

        this._regular = this.getBlock(1, Assets.Atlases.AtlasesEyelander.Frames.Cs2, this.onRegular);
        this._regular.x = this.game.width / 2;
        this._regular.y = this.BUTTON_Y_PAD;
        this.cursors.add(this._regular);

        this._little = this.getBlock(.75, Assets.Atlases.AtlasesEyelander.Frames.Cs1, this.onLittle);
        this._little.x = this._regular.x - this.BUTTON_X_PAD;
        this._little.y = this._regular.y;
        this.cursors.add(this._little);

        this._big = this.getBlock(1.25, Assets.Atlases.AtlasesEyelander.Frames.Cs3, this.onBig);
        this._big.x = this._regular.x + this.BUTTON_X_PAD;;
        this._big.y = this._regular.y;
        this.cursors.add(this._big);

        return container;
    }

    addInputModeButtons(): Phaser.Group {
        var container = this.game.add.group();

        this.inputs = this.game.add.group();
        container.add(this.inputs);

        var subheader1 = this.game.add.bitmapText(0, 0, Assets.BitmapFonts.FontsVag.getName(), "Input Mode", 45, container);
        subheader1.tint = 0xffffff;
        subheader1.align = "center";
        subheader1.x = this.game.width / 2;
        subheader1.anchor.setTo(.5, 0);

        this._mouse = this.getBlock(1.25, Assets.Atlases.AtlasesEyelander.Frames.MouseBtn, this.onMouse);
        this._mouse.x = this.game.width / 2 - (this.BUTTON_X_PAD * .5);
        this._mouse.y = this.BUTTON_Y_PAD;
        this.inputs.add(this._mouse);

        this._touch = this.getBlock(1, Assets.Atlases.AtlasesEyelander.Frames.TouchBtn, this.onTouch);
        this._touch.x = this._mouse.x + (this.BUTTON_X_PAD);
        this._touch.y = this._mouse.y;
        this.inputs.add(this._touch);

        return container;
    }

    addHandButtons(): Phaser.Group {
        var container = this.game.add.group();

        this.hands = this.game.add.group();
        container.add(this.hands);

        var subheader1 = this.game.add.bitmapText(0, 0, Assets.BitmapFonts.FontsVag.getName(), "Cursor Direction", 45, container);
        subheader1.tint = 0xffffff;
        subheader1.align = "center";
        subheader1.x = this.game.width / 2;
        subheader1.anchor.setTo(.5, 0);

        this._right = this.getBlock(1.25, Assets.Atlases.AtlasesEyelander.Frames.RightBtn, this.onRight);
        this._right.x = this.game.width / 2 - (this.BUTTON_X_PAD * .5);
        this._right.y = this.BUTTON_Y_PAD;
        this.hands.add(this._right);

        this._left = this.getBlock(1, Assets.Atlases.AtlasesEyelander.Frames.LeftBtn, this.onLeft);
        this._left.x = this._right.x + (this.BUTTON_X_PAD);
        this._left.y = this._right.y;
        this.hands.add(this._left);

        return container;
    }

    private onMouse():void
    {
        SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.SettingsMouseMode.toString());

        SFX.play("Button Click 4");
        this.setInputMode(InputMode.Mouse);
        this.transition("settings");
    }

    private onTouch():void
    {
        SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.SettingsTouchMode.toString());

        SFX.play("Button Click 4");
        this.setInputMode(InputMode.Touch);
        this.transition("settingstouch");
    }

    private onLeft(): void {
        SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.SettingsLeftDirection.toString());

        SFX.play("Button Click 4");
        this.setHand(Hand.Left);
    }

    private onRight(): void {
        SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.SettingsRightDirection.toString());

        SFX.play("Button Click 4");
        this.setHand(Hand.Right);
    }

    private onRegular(): void {
        SFX.play("Button Click 4");
        SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.SettingsCursorMedium.toString());
        this.setCursor(CursorSize.Regular);
    }

    private onLittle(): void {
        SFX.play("Button Click 4");
        SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.SettingsCursorSmall.toString());
        this.setCursor(CursorSize.Little);
    }

    private onBig(): void {
        SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.SettingsCursorLarge.toString());
        SFX.play("Button Click 4");
        this.setCursor(CursorSize.Big);
    }

    private deselectAllShapes(): void {
        this.shapes.setAll("alpha", .5);
    }

    private deselectAllCursors(): void {
        this.cursors.setAll("alpha", .5);
    }

    private deselectAllHands(): void {
        this.hands.setAll("alpha", .5);
    }

    private deselectAllTime(): void {
        this.times.setAll("alpha", .5);
    }

    private deselectAllInputs(): void {
        this.inputs.setAll("alpha", .5);
    }

    protected setGameSpeed(speed: number): void {
        this.model.gamespeed = speed;
        this.deselectAllTime();

        switch (speed) {
            case GameSpeed.Slow:
                this._slow.alpha = 1;
                break;
            case GameSpeed.Normal:
                this._normal.alpha = 1;
                break;
            case GameSpeed.Fast:
                this._fast.alpha = 1;
                break;
        }
    }

    protected setSize(size: ShapeSize): void {

        this.model.shapesize = size;
        this.deselectAllShapes();

        switch (size) {
            case ShapeSize.Small:
                this._small.alpha = 1;
                break;
            case ShapeSize.Medium:
                this._medium.alpha = 1;
                break;
            case ShapeSize.Large:
                this._large.alpha = 1;
                break;
        }
    }

    protected setHand(hand:Hand):void
    {
        this.model.hand = hand;
        this.deselectAllHands();

        switch (hand) {
            case Hand.Left:
                this._left.alpha = 1;
                break;
            case Hand.Right:
                this._right.alpha = 1;
                break;
        }
    }

    protected setInputMode(mode:InputMode):void
    {
        InputConfig.Mode = mode;
        this.deselectAllInputs();

        switch (mode) {
            case InputMode.Mouse:
                this._mouse.alpha = 1;
                break;
            case InputMode.Touch:
                this._touch.alpha = 1;
                break;
        }
    }

    protected setCursor(cursor: CursorSize): void {

        this.model.cursorsize = cursor;
        this.deselectAllCursors();

        switch (cursor) {
            case CursorSize.Little:
                this._little.alpha = 1;
                break;
            case CursorSize.Regular:
                this._regular.alpha = 1;
                break;
            case CursorSize.Big:
                this._big.alpha = 1;
                break;
        }
    }

    private onMedium(): void {
        SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.SettingsShapeMedium.toString());

        SFX.play("Button Click 4");
        this.setSize(ShapeSize.Medium);
    }

    private onSmall(): void {
        SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.SettingsShapeSmall.toString());
        SFX.play("Button Click 4");
        this.setSize(ShapeSize.Small);
    }

    private onLarge(): void {
        SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.SettingsShapeLarge.toString());
        SFX.play("Button Click 4");
        this.setSize(ShapeSize.Large);
    }

    private onNormal(): void {
        SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.SettingsSpeedNormal.toString());
        SFX.play("Button Click 4");
        this.setGameSpeed(GameSpeed.Normal);
    }

    private onSlow(): void {
        SFX.play("Button Click 4");
        SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.SettingsSpeedSlow.toString());
        this.setGameSpeed(GameSpeed.Slow);
    }

    private onFast(): void {
        SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.SettingsSpeedFast.toString());
        SFX.play("Button Click 4");
        this.setGameSpeed(GameSpeed.Fast);
    }

    onBack(): void {
        SFX.play("Button Click 3");
        this.transition("title");
    }
}
