import { SessionVO } from './../core/vo/SessionVO';
import { Model } from './../core/model/Model';
import { API } from './../services/API';
import { StandardUI } from './../view/StandardUI';
import { DomUtils } from './../utils/utils';
import { ShapeSpawner } from './../core/ShapeSpawner';
import { Shape } from './../view/Shape';
import { Pallete } from './../core/Pallete';
import { ShapeVO } from './../core/vo/ShapeVO';
import * as Assets from '../assets';
import * as AssetUtils from '../utils/assetUtils';
import State from '../core/State';
import { Form } from "../enum/Form";
import Music from '../utils/Music';
import SFX from '../utils/SFX';


export default class Completion extends State {

    public create(): void {
        
        var bg = this.game.add.sprite(0, 0, "Menu-BG");

        var header = this.game.add.bitmapText(0, 0, Assets.BitmapFonts.FontsVag.getName(), "Game Complete", 120);
        header.tint = 0xffffff;
        header.align = "center";
        header.x = this.game.width / 2;
        header.y = 300;
        header.anchor.setTo(.5, 0);

        var body = this.game.add.bitmapText(0, 0, Assets.BitmapFonts.FontsVag.getName(), "Congratulations!  Come back \nand play again soon.", 80);
        body.tint = 0xffffff;
        body.align = "center";
        body.x = this.game.width / 2;
        body.y = 510;
        body.anchor.setTo(.5, 0);

        var k = "back-btn.png";
        var backBtn = this.game.add.button(60, 60, Assets.Atlases.AtlasesEyelander.getName(), this.onBack, this, k, k, k, k);
        backBtn.fixedToCamera = true;
        backBtn.anchor.setTo(0, 0);

        SFX.play(Assets.Audiosprites.AudiospritesSfx.Sprites.RoundEndExcellent.toString());

        this.sendCompletionDataToAPI();
    }

    private sendCompletionDataToAPI():void
    {
        this.model.playthrough = this.model.playthrough+1; //new playthrough
        this.model.resetSession(); //resets game progress to zero

        //API.ON_SERVER_UPDATED.addOnce(this.onServerUpdated, this);
        API.SendData(this.model.getAPIFormattedData());
    }

    private onServerUpdated(data:any):void
    {
        //console.log("server has been updated " + JSON.stringify(data));
    }

    private onBack():void
    {
        SFX.play("Button Click 3");
        this.transition("title");
    }

}
